import store from '../store/index';
import moment from 'moment'

export function dateFormat (date) {
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat('EN', { year: 'numeric', month: 'numeric', day:'numeric' }).format(dateObj)
}

// Floor data value
export function round (num) {
  return Math.floor(num * 100 )/100
}

export function dateFormatApi (startDate) {
  let current = moment(new Date()).startOf('day'), startMoment, endMoment;
  startMoment = moment(startDate).startOf(store.getState().chartType);
  endMoment = moment.min(moment(startMoment).add(1, store.getState().chartType), moment(current));

  return { from: startMoment.format("YYYY-MM-DDTHH:mm:ss") + "Z", to: endMoment.format("YYYY-MM-DDTHH:mm:ss") + "Z" };
}

export function checkLimitDate(date, week, month, year, thisObj, picker = true) {
  const elDate = date;
  const elweek = week;  
  const elMonth = month;
  const elYear = year;

  let currentFormat, currentMax;
  switch (store.getState().chartType) {
    case 'year': {
      currentFormat = moment(elYear).format("YYYY");
      currentMax = moment(new Date()).subtract(1, 'day').format("YYYY");
      break;
    }
    case 'month': {
      currentFormat = moment(elMonth).format("M YYYY");
      currentMax = moment(new Date()).subtract(1, 'day').format("M YYYY");
      break;
    }
    case 'week': {
      currentFormat = moment(elweek).format("wo YYYY");
      currentMax = moment(new Date()).subtract(1, 'day').format("wo YYYY");
      break;
    }
    case 'day': {
      currentFormat = moment(elDate).format("D M YYYY");
      currentMax = moment(new Date()).format("D M YYYY");
      break;
    }
  }
  let value = false;

  if (currentFormat === currentMax) { // If date reached disable button
    value = true
  }

  if (picker) {
    thisObj.props.checkDisabled(value)
  }else{
    thisObj.checkDisabled(value)
  }

  // this.setState({maxReached: value})
}

export function calendarClassMark (date) {
  if (store.getState().markAlert.find(el => dateFormat(el) === dateFormat(date.date))) {
    return 'highlight highlight__alert';
  }
  if (store.getState().markInfo.find(el => dateFormat(el) === dateFormat(date.date))) {
    return 'highlight highlight__info';
  }
}

export function toggleOverflowed(thisObj) {
  const el = thisObj.mainContainer
  const overFlowed = el.offsetHeight < el.scrollHeight || el.offsetWidth < el.scrollWidth

  thisObj.setState({ overflowed: overFlowed });
}
