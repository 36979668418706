import { arrowForwardOutline } from 'ionicons/icons';
import { IonContent, IonButton, IonPage, IonIcon, useIonViewDidEnter } from '@ionic/react';
import './Notifications.scss';
import { useHistory } from "react-router-dom";
import { UpdateKeyOfApp, CheckKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';
import { useTranslation } from "react-i18next"

const Notifications: React.FC = () => {

  const { t, i18n } = useTranslation();

  const history = useHistory();
  const { updateAppKey } = UpdateKeyOfApp();
  const { appKey } = CheckKeyOfApp();

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
  });

  function whereToGoFromHere() {
    updateAppKey(Constants.KEY_STATUS,Constants.STATUS_DONGLE);
    history.replace('/setup/dongle');
  }

  return (
    <IonPage>
      <IonContent className="notifications" forceOverscroll={false}>
        <img src="assets/logo-flow.svg" className="logo" width="90" height="70" alt="" />
        <img src="assets/img-bell.svg" className="notification-icon" width="56" height="50" alt="" />
        <h1>{t('notifications.title')}</h1>
        <p>
        {t('notifications.info')}
        </p>  
        <a href="/setup/dongle" className="notification-link">{t('notifications.later')}</a>
        <div className="action action--bottom">
          <IonButton shape="round" expand="block" onClick={() => whereToGoFromHere()}>{t('notifications.ok')} <IonIcon color="light" icon={arrowForwardOutline}></IonIcon></IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
