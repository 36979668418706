import React, { Component } from 'react';

import Calendar from 'react-calendar';
import { CSSTransition } from "react-transition-group";
import moment from 'moment';
import './DatePicker.scss';

import store from '../store/index';
import { checkLimitDate } from '../helpers/helpers'

class DatePickerApp extends Component {

  constructor(props) {
    super(props);
  }  

  state = {
    today: new Date(),
    dateMaxValue: new Date(),
    showCalendar: false,
    maxValueMonth: moment(new Date()).subtract(1, 'day').toDate(),
    maxValueWeek: moment(new Date()).subtract(1, 'day').toDate(),
    maxValueDay: moment(new Date()).toDate(),
    maxReached : false
  }

  onChange = (date) => {
    const dateSelect = moment(date).format("D M YYYY")
    let currentDate;
    switch (store.getState().chartType) {
      case 'year': currentDate = moment(store.getState().currentYear).format("D M YYYY"); break;
      case 'month': currentDate = moment(store.getState().currentMonth).format("D M YYYY"); break;
      case 'week': currentDate = moment(store.getState().currentWeek).format("D M YYYY"); break;
      case 'day': currentDate = moment(store.getState().currentDate).format("D M YYYY"); break;
    }
    if (dateSelect !== currentDate) { // IF NOT SAME VALUE
      const current = date
      this.props.onDateSelected({date:current})

      this.toggleCalendar(false);
      checkLimitDate(date, date, date, date, this);
    }
  }

  toggleCalendar = (state) => {
    this.setState({showCalendar: state})
    this.props.setIsVisible(!this.props.isVisible)
  }

  componentDidUpdate(prev) {
    if(this.props.currentDate !== prev.currentDate) {
      checkLimitDate(this.props.currentDate, this.props.currentWeek, this.props.currentMonth, this.props.currentYear, this);
    }
  }

  componentDidMount() {
    checkLimitDate(this.props.currentDate, this.props.currentWeek, this.props.currentMonth, this.props.currentYear, this);
  }

  render() {
    let calendarValue, calendarMaxDate, calendarMaxDetail;
    switch (store.getState().chartType) {
      case 'year': {
        calendarValue = this.props.currentYear;
        calendarMaxDate = moment().toDate();
        calendarMaxDetail = 'decade';
        break;
      }
      case 'month': {
        calendarValue = this.props.currentMonth;
        calendarMaxDate = this.state.maxValueMonth;
        calendarMaxDetail = 'year';
        break;
      }
      case 'week': {
        calendarValue = this.props.currentWeek;
        calendarMaxDate = this.state.maxValueWeek;
        calendarMaxDetail = 'month';
        break;
      }
      case 'day': {
        calendarValue = this.props.currentDate;
        calendarMaxDate = this.state.maxValueDay;
        calendarMaxDetail = 'month';
        break;
      }
    }

    return (
      <React.Fragment>

        <div className="charts__dateselect--buttons">
          <button onClick={this.props.onDecDate} className="button nav">‹</button>

          <button className="calendar__trigger" onClick={() => this.toggleCalendar(true)}>
            <span>{this.props.formatDateSelect()}</span>
          </button>

          <button onClick={this.props.onIncDate} disabled={this.props.isDisabled} className="button nav">›</button>
        </div>

        <CSSTransition
          in={this.state.showCalendar && this.props.isVisible}
          timeout={300}
          classNames="anim__fade"
          unmountOnExit appear>

          <Calendar
            className="charts"
            onChange={this.onChange}
            value={calendarValue}
            locale={this.props.language}
            useWeekdaysShort={true}
            minDate={store.getState().minValueDate}
            maxDate={calendarMaxDate}
            maxDetail={calendarMaxDetail}
          />
        </CSSTransition>
      </React.Fragment>
    );
  }
}

export default DatePickerApp;
