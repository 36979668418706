import { arrowForwardOutline } from 'ionicons/icons';
import { useState } from 'react';
import { IonContent, IonInput, IonPage, IonButton, IonItem, IonIcon, IonLabel, IonToast, IonLoading, useIonViewDidEnter } from '@ionic/react';
import './LoginPassword.scss';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { CheckKeyOfApp, UpdateKeyOfApp } from '../../hooks/AppKey';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import * as Constants from '../../hooks/Constants';
import { Browser } from '@capacitor/browser';
import { useTranslation } from "react-i18next"

const LoginPassword: React.FC = () => {

  const { t, i18n } = useTranslation();

  const [showError, setShowError] = useState(false);
  const [showErrorTxt, setShowErrorTxt] = useState<string>('');

  const [passValue, setPassValue] = useState<string>('');

  const history = useHistory();
  const { appKey } = CheckKeyOfApp();
  const { updateAppKey } = UpdateKeyOfApp();

  const [showToast, setShowToast] = useState(false);
  const [showToastTxt, setShowToastTxt] = useState<string>('');

  const [showLoading, setShowLoading] = useState(false);

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
  });

  function checkPassword() {
    if (!isEmpty(passValue)) {
      setShowLoading(true);
      setShowError(false);
      validateFluviusPassword(passValue);
    }
    else {
      setShowErrorTxt(t('loginpassword.empty_password'));
      setShowError(true);
    }
  }

  function validateFluviusPassword(pwd:string) {
    appKey(Constants.KEY_EMAIL).then(
      value => {
        var email  = value;
        const params = new URLSearchParams();
        params.append('client_id', Constants.API_CLIENT_ID);
        params.append('client_secret', Constants.API_CLIENT_SECRET);
        params.append('grant_type', Constants.API_GRANT_TYPE);

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        axios.post(Constants.API_URL_TOKEN, params, config)
        .then(
          res => {
            var result:any = res.data;
            var token = result.access_token;

            var json:any = { mail: email };
            json.pass = pwd;
        
            axios.post(
              Constants.API_URL_CHECK_USER, 
              JSON.stringify(json),
              { 
                headers: {
                  "Authorization" : `Bearer ${token}`,
                  'Content-Type' : 'application/json' 
                } 
              }
            )
            .then(
                res2 => {
                  var result2:any = res2.data;
                  if (result2.error) {
                    setShowErrorTxt(t('loginpassword.invalid_password'));
                    setShowError(true);
                    setShowLoading(false);
                  }
                  else {
                    axios.get(Constants.API_URL_GET_USER + email, { headers: {"Authorization" : `Bearer ${token}`} })
                    .then(
                        res3 => {
                          var result3:any = res3.data;
                          var ean_code = result3.succes.ean_code;
                          var slug = result3.succes.slug;
                          var electricity_price_day = result3.succes.electricity_price_day;
                          var electricity_price_night = result3.succes.electricity_price_night;
                          var number_of_adults = result3.succes.number_of_adults;
                          var number_of_children = result3.succes.number_of_children;
                          var dongle_id = result3.succes.dongle_id;
                          var rate_type = result3.succes.rate_type;
                          
                          updateAppKey(Constants.KEY_STATUS,Constants.STATUS_DONGLE);           
                          updateAppKey(Constants.KEY_INJECTION_RATE,'0.3');               
                          updateAppKey(Constants.KEY_DAILY_RATE,electricity_price_day);
                          updateAppKey(Constants.KEY_NIGHTLY_RATE,electricity_price_night);
                          updateAppKey(Constants.KEY_NUMBER_ADULTS,number_of_adults);
                          updateAppKey(Constants.KEY_NUMBER_CHILDREN,number_of_children);
                          updateAppKey(Constants.KEY_EAN,ean_code);
                          updateAppKey(Constants.KEY_SLUG,slug);
                          updateAppKey(Constants.KEY_DONGLE_ID,dongle_id);
                          updateAppKey(Constants.KEY_RATE_TYPE,rate_type);

                          if(!Capacitor.isNativePlatform()) {
                            updateAppKey(Constants.KEY_STATUS,Constants.STATUS_DASHBOARD);
                            history.replace('/dashboard');
                          }
                          else {
                            // dus deze api call wordt nu uitgevoerd ipv deze hierboven
                            axios.get(Constants.API_URL_DATA_REALTIME + '?id=' + dongle_id + '&slug=' + slug)
                            .then(
                              res => {
                                let view:any = res.data;
                                if (view.data === undefined || view.data[0] === undefined) {
                                  // er is nog geen data gekoppeld aan die user
                                  history.replace('/setup/dongle');
                                }
                                else {
                                  updateAppKey(Constants.KEY_STATUS,Constants.STATUS_DASHBOARD);
                                  history.replace('/dashboard');
                                }
                              }    
                            )
                            .catch(
                              error => {
                                history.replace('/setup/dongle');
                            });
                          }  
                          
                        })
                    .catch((error3) => {
                      setShowToastTxt(t('loginpassword.connection_problem'));
                      setShowToast(true);
                      setShowLoading(false);
                    });
                  }
                })
            .catch((error2) => {
              setShowToastTxt(t('loginpassword.connection_problem'));
              setShowToast(true);
              setShowLoading(false);
            });
          }
        )
        .catch(
          error => {
            setShowToastTxt(t('loginpassword.connection_problem'));
            setShowToast(true);
            setShowLoading(false);
          }
        );
      }
    );
  }

  function setText(str:string) {
    setPassValue(str);
  }

  function isEmpty(str:string) {
    return (!str || str.length === 0 );
  }

  function handleKey(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }  
      checkPassword();
    }
  }

  function goToBrowser() {
    Browser.open({ url: 'https://flow-energy.be/user/password' });
  }

  return (
    <IonPage>
      <IonContent className="password" forceOverscroll={false}>
        <IonLoading
          cssClass="flow-loading"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('loginpassword.patience')}
        />
        <img src="assets/logo-flow.svg" className="logo" width="90" height="70" alt="" />
        <h1>{t('loginpassword.title')}</h1>
        { showError ? (<IonLabel className="message message--error">{ showErrorTxt }</IonLabel>) : '' }
        <IonItem lines="none">
          <IonInput onKeyPress={e=> handleKey(e.key)} type="password" onIonChange={e => setText(e.detail.value!)}></IonInput>
        </IonItem>
        <a href="javascript:void(0);" onClick={() => goToBrowser()} target="_blank" className="forgot-link">{t('loginpassword.password_forgotten')}</a>
        <div className="action action--bottom">
          <IonButton shape="round" expand="block" onClick={() => checkPassword()}>{t('loginpassword.start')} <IonIcon color="light" icon={arrowForwardOutline}></IonIcon></IonButton>
        </div>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={ showToastTxt }
          duration={1000}
          position="top"
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default LoginPassword;
