import { IonContent, IonList, IonPage, IonItem, IonLabel, useIonViewDidEnter, IonButton, IonToast } from '@ionic/react';
import './Wifi.scss';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BLE } from '@ionic-native/ble';
import { Capacitor } from '@capacitor/core';
import axios from 'axios';
import { CheckKeyOfApp, UpdateKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';
import { useTranslation } from "react-i18next"

const Wifi: React.FC = () => {

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { appKey } = CheckKeyOfApp();
  const { updateAppKey } = UpdateKeyOfApp();

  const [showSkipBtn,setShowSkipBtn] = useState(true);
  const [tryAgain, setTryAgain] = useState(false);
  const [tryAgainTxt, setTryAgainTxt] = useState<string>('');
  const [list, updateWifiList] = useState<string[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [showToastTxt, setShowToastTxt] = useState<string>('');
  const [deviceId, setDeviceId] = useState<string>('');

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
    appKey(Constants.KEY_DONGLE_ID).then(
      value => {
        appKey(Constants.KEY_SLUG).then(
          value2 => {
            axios.get(Constants.API_URL_DATA_REALTIME + '?id=' + value + '&slug=' + value2)
              .then(
                res => {
                  let view:any = res.data;
                  if (view.data === undefined || view.data[0] === undefined) {
                    // er is nog geen data gekoppeld aan die user
                  }
                  else {
                    setShowSkipBtn(false);
                  }
                }    
              )
              .catch(
                error => {
                  console.log("error in realtime api: " + error);
                });
          }
        );
      }
    );
    appKey(Constants.KEY_ACTIVE_PERIPHERAL_ID).then(
      value => {
        if (value) {
          setDeviceId(value);
          updateAppKey(Constants.KEY_ACTIVE_PERIPHERAL_ID,'');
        }
        if(!Capacitor.isNativePlatform()) {
          scanForDongle();
        } 
        else {
          if (Capacitor.getPlatform() == 'ios') {
            BLE.isEnabled().then(() => {
              scanForDongle();
            })
            .catch(err => {
              // ios sometimes need to awake the bluetooth function, even is bluetooth is activated
              setTimeout(() => 
              {
                scanForDongle();
              },
              2000);
            });
          }
          else {
            scanForDongle();
          }
        }
      }
    );  
    
        
  });

  function whereToGoFromHere(wifiName:string) {
    updateAppKey(Constants.KEY_WIFI,wifiName);
    history.replace('/setup/wifipassword');
  }

  async function scanForDongle() {
    if (Capacitor.getPlatform() == 'ios') {
      scanForDongleIos();
    }
    else {
      scanForDongleAndroid();
    }
  }

  async function scanForDongleIos() {
    setTryAgainTxt(t('wifi.search_for_dongle'));
    setTryAgain(false);
    BLE.isEnabled().then(() => {
      if (deviceId != '') {
        BLE.isConnected(deviceId).then(
          data => {
            readWifiListFromDongle(deviceId);
          },
          err => {
            BLE.connect(deviceId).subscribe(
              peripheral => onConnected(peripheral),
              peripheral => onDeviceDisconnected(peripheral)
            );
          }
        );
      }
      else {
        BLE.startScan([]).subscribe(
          device => onDeviceDiscovered(device),
          error => scanError(error)
        );

        setTimeout(BLE.stopScan,10000,scanTimeoutOk(),scanTimeoutError());
      }
    })
    .catch(err => {
      //scanForDongleIos();
      setTryAgainTxt(t('wifi.try_again'));
      setTryAgain(true);
      setShowToastTxt('Activeer Bluetooth op je telefoon.');
      setShowToast(true);
    });
    
  }

  async function scanForDongleAndroid() {
    BLE.isEnabled().then(() => {
      setTryAgainTxt('Zoeken naar Dongle...');
      setTryAgain(false);
      if (deviceId != '') {
        readWifiListFromDongle(deviceId);
        BLE.isConnected(deviceId).then(
          data => {
            readWifiListFromDongle(deviceId);
          },
          err => {
            BLE.connect(deviceId).subscribe(
              peripheral => onConnected(peripheral),
              peripheral => onDeviceDisconnected(peripheral)
            );
          }
        );
      }
      else {
        BLE.startScan([]).subscribe(
          device => onDeviceDiscovered(device),
          error => scanError(error)
        );

        setTimeout(BLE.stopScan,10000,scanTimeoutOk(),scanTimeoutError());
      }  
    })
    .catch(err => {
      setTryAgainTxt(t('wifi.try_again'));
      setTryAgain(true);
      setShowToastTxt(t('wifi.activate_bluetooth'));
      setShowToast(true);
    });
    
  }

  function scanTimeoutOk() {
    if (deviceId == '') {
      setTryAgainTxt(t('wifi.try_again'));
      setTryAgain(true);
      // setShowToastTxt('Timeout (OK)');
      // setShowToast(true);
    }  
  }

  function scanTimeoutError() {
    if (deviceId == '') {
      setTryAgainTxt(t('wifi.try_again'));
      setTryAgain(true);
      // setShowToastTxt('Timeout (Error)');
      // setShowToast(true);
    }
  }

  // If location permission is denied, you'll end up here
  function scanError(error:any) {
    setTryAgainTxt(t('wifi.try_again'));
    setTryAgain(true);
    setShowToastTxt(t('wifi.permission_problem'));
    setShowToast(true);
  }

  function onDeviceDiscovered(device:any) {
    if (device.name) {
      var deviceName = JSON.stringify(device.name);
      if (deviceName.indexOf('Flow Dongle') != -1) {
        setTryAgainTxt(t('wifi.make_connection'));
        setDeviceId(device.id);
        BLE.isConnected(device.id).then(
          data => {
            readWifiListFromDongle(device.id);
          },
          err => {
            BLE.connect(device.id).subscribe(
              peripheral => onConnected(peripheral),
              peripheral => onDeviceDisconnected(peripheral)
            );
          }
        );
      }  
    }  
  }

  async function onConnected(peripheral:any) {
    setTryAgainTxt(t('wifi.get_wifi_info'));
    updateAppKey(Constants.KEY_PERIPHERAL_ID,peripheral.id);
    readWifiListFromDongle(peripheral.id);
  }

  function readWifiListFromDongle(peripheralId:string) {

    BLE.read(peripheralId,Constants.SERVICE_UUID_WIFIS,Constants.CHARACTERISTIC_UUID_WIFIS).then(
      function(buffer) {
        
        var buff = new Uint8Array(buffer);
        var arr = new TextDecoder().decode(buff).split('|');

        var wifi = new Array<string> ();

        for (var i = 0;i<arr.length;i++) {
          if (arr[i] != ''  && wifi.indexOf(arr[i]) < 0) {
            wifi.push(arr[i]);
          }
        }

        if (wifi.length == 0) {
          setShowToastTxt(t('wifi.no_wifi_found'));
          setShowToast(true);
        }
        
        updateWifiList(wifi);

        setTryAgainTxt(t('wifi.try_again'));
        setTryAgain(true);
      
      },
      function(err) {
        setShowToastTxt(t('wifi.wifi_error'));
        setShowToast(true);
        setTryAgainTxt(t('wifi.try_again'));
        setTryAgain(true);
    
      }
    )
  }

  function onDeviceDisconnected(peripheral:any) {
    setTryAgainTxt(t('wifi.try_again'));
    setTryAgain(true);
  }

  function nextPage() {
    updateAppKey(Constants.KEY_STATUS,Constants.STATUS_DASHBOARD);
    history.replace('/dashboard');
  }

  return (
    <IonPage>
      <IonContent className="wifi">
        <h1>{t('wifi.search_for_wifi')}</h1>
        <p className="wifi-info">{t('wifi.info')}</p>
        <IonButton hidden={showSkipBtn} onClick={() => nextPage()} size="small" shape="round" className="ion-margin ">{t('wifi.skip')}</IonButton>
        <IonButton onClick={() => scanForDongle()} disabled={!tryAgain} shape="round" className="ion-margin">{ tryAgainTxt }</IonButton>
        <div className="wifi-list">
          <IonList>
            {list.map((wifi,index) => (
              <IonItem key={index} onClick={() => whereToGoFromHere(wifi)}>
                <IonLabel>{wifi}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </div>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={ showToastTxt }
          duration={1000}
          position="top"
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default Wifi;
