import { ComputedData } from './structures';
import * as VIEWTYPE from './constants';

export const _EMPTY_SEGMENT = -1;

export class DataSegment {
	constructor(segmentID, queryParams, viewType) {
		this.id = segmentID;
		this.queryParams = queryParams;
		this.viewType = viewType;
		//-----
		this.processed = false;
		this.data = null;
		this.computedData = null;
		//-----
		if (segmentID == _EMPTY_SEGMENT)
			this.Empty();
	}
	Empty() {
		let emptyArray = [];
		this.SetData(emptyArray);
		this.computedData = new ComputedData(this.id);
	}
	IsEmpty() { return this.data.length <= 0; }
	SetData(data) {
		this.data = data;
		if (this.data == null)
			this.Empty();
		//-----
		if (this.IsEmpty())
			this.processed = true;		// No data to process for this segment
		else {
			let firstTimestamp = data[0].timestampStart;
			this.computedData = new ComputedData(this.id, firstTimestamp);
			({ hour: this.prevHour, day: this.prevDay, month: this.prevMonth, year: this.prevYear } = this.ExplodeDate(firstTimestamp));
		}
	}
	ExplodeDate(timestamp) {
		let dateToExplode = new Date(timestamp);
		return { hour: dateToExplode.getUTCHours(), day: dateToExplode.getUTCDate(), month: dateToExplode.getUTCMonth(), year: dateToExplode.getUTCFullYear() };
	}
	ProcessStatement(statement) {
		// Check for time criteria change
		//-------------------------------
		let { hour, day, month, year } = this.ExplodeDate(statement.timestampStart);
		this.dayTime = (hour >= 7 && hour < 23) ? "day" : "night";
		//-----
		let isNewYear = year > this.prevYear;
		let isNewMonth = month > this.prevMonth || isNewYear;
		let isNewDay = day > this.prevDay || isNewMonth;
		let isNewHour = hour > this.prevHour || isNewDay;
		switch (this.viewType) {
			case VIEWTYPE.YEAR:
				if (isNewMonth) this.ClotureCurrentComputation(statement);
				break;
			case VIEWTYPE.MONTH:
			case VIEWTYPE.WEEK:
				if (isNewDay) this.ClotureCurrentComputation(statement);
				break;
			case VIEWTYPE.DAY:
				if (isNewHour) this.ClotureCurrentComputation(statement);
				break;
		}
		//-----
		[this.prevHour, this.prevDay, this.prevMonth] = [hour, day, month];

		// Process the new statement data
		//-------------------------------
		this.computedData.ImportStatement(statement.measurement[0], this.dayTime);
	}
	FormatData() {
		if (!this.IsEmpty()) {
			this.data.forEach(statement => this.ProcessStatement(statement));
			this.processed = true;
		}
	}
	MergeInto(otherSegment) {
		let createEmptyRecord = !this.processed || this.IsEmpty();
		if (createEmptyRecord)
			(new ComputedData(this.id, this.queryParams.start)).MergeInto(otherSegment.computedData);
		else
			this.computedData.MergeInto(otherSegment.computedData);
	}
	ClotureCurrentComputation(statement) {
		if (!this.IsEmpty())
			this.computedData.ClotureCurrentComputation(statement);
	}
	ClotureOverview() { this.computedData.ClotureOverview(); }
}