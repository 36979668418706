import { arrowForwardOutline } from 'ionicons/icons';
import { useState } from 'react';
import { IonContent, IonButton, IonPage, IonCheckbox, IonLabel, IonItem, IonIcon, useIonViewDidEnter } from '@ionic/react';
import './Gdpr.scss';
import { useHistory } from "react-router-dom";
import { UpdateKeyOfApp, CheckKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';
import { useTranslation } from "react-i18next"

const Gdpr: React.FC = () => {

  const { t, i18n } = useTranslation();

  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const { updateAppKey } = UpdateKeyOfApp();
  const { appKey } = CheckKeyOfApp();

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
  });

  function whereToGoFromHere() {
    updateAppKey(Constants.KEY_STATUS,Constants.STATUS_LOGIN);
    history.replace('/setup/email');
  }

  return (
    <IonPage>
      <IonContent className="gdpr" forceOverscroll={false}>
        <img src="assets/logo-flow.svg" className="logo" width="90" height="70" alt="" />
        <h1>{t('gdpr.title')}</h1>
        <div className="gdpr-text">
          <p>
            {t('gdpr.text1')}<br /><br />
            {t('gdpr.text2')} <a href="https://www.flow-energy.be/sites/flow_energy/files/2021-11/Privacybeleid.pdf" target="_blank">{t('gdpr.link1')}</a>.
          </p>
        </div>
        <IonItem lines="none">
            <IonCheckbox slot="start" checked={checked} onIonChange={e => setChecked(e.detail.checked)} />
            <IonLabel className="ion-text-wrap">{t('gdpr.check')}<br /><a href="https://www.flow-energy.be/sites/flow_energy/files/2021-11/Servicevoorwaarden.pdf " target="_blank">{t('gdpr.checklink')}</a></IonLabel>
          </IonItem>
        <div className="action action--bottom">
          <IonButton  shape="round" expand="block" onClick={() => whereToGoFromHere()} disabled={!checked}>{t('gdpr.submit')} <IonIcon color="light" icon={arrowForwardOutline}></IonIcon></IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Gdpr;
