import { arrowForwardOutline } from 'ionicons/icons';
import { useState } from 'react';
import { IonContent, IonPage, IonButton, IonIcon, IonSelect, IonSelectOption, useIonViewDidEnter } from '@ionic/react';
import './Welcome.scss';
import { useHistory } from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from "react-i18next"
import { UpdateKeyOfApp, CheckKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';

const Welcome: React.FC = () => {

  const { t, i18n } = useTranslation();

  const history = useHistory();
  const { updateAppKey } = UpdateKeyOfApp();
  const { appKey } = CheckKeyOfApp();
  const [checked, setChecked] = useState(false);

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
  });  

  function whereToGoFromHere() {
    history.replace('/setup/gdpr');
  }

  function changeActiveLanguage(str:string) {
    updateAppKey(Constants.KEY_LANG,str);
    i18n.changeLanguage(str);
    setChecked(true);
  }

  return (
    <IonPage>
      <IonContent className="welcome" forceOverscroll={false}>
        <div className="container">
          <img src="assets/logo-flow.svg" className="logo" width="90" height="70" alt="" />
          <h1>{t('welcome.title_part1')}<br />{t('welcome.title_part2')}</h1>
          <div className="welcome-image">
            <Player
              autoplay
              loop
              src="assets/img-houses-animated.json"
              style={{ height: '100%', width: '100%' }}
            >
            </Player>
          </div>
          <div className="action action--bottom">
            <div className="language-selection">
              <IonSelect interface='action-sheet' placeholder="Selecteer / Sélectionner / Select" onIonChange={(ev) => changeActiveLanguage(ev.detail.value)}>
                <IonSelectOption value="nl">Nederlands</IonSelectOption>
                <IonSelectOption value="fr">Français</IonSelectOption>
                <IonSelectOption value="en">English</IonSelectOption>
              </IonSelect>
            </div>
            <IonButton shape="round" expand="block" disabled={!checked}  onClick={() => whereToGoFromHere()}>{t('welcome.start')} <IonIcon color="light" icon={arrowForwardOutline}></IonIcon></IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
