import { IonContent, useIonViewDidEnter, IonItemDivider, IonButton, IonPage, IonTabBar, IonTabButton, IonIcon, IonLabel, IonFooter, IonList, IonItem, IonToggle, IonInput, IonToast, IonLoading, IonSelect, IonSelectOption } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { CheckKeyOfApp, UpdateKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';
import axios from 'axios';
import { barChartOutline, settingsOutline, alarmOutline } from 'ionicons/icons';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import './Settings.scss';
import { useTranslation } from "react-i18next"

const Settings: React.FC = () => {

  const { t, i18n } = useTranslation();

  const [checked, setChecked] = useState(false);
  const [appleUser,setAppleUser] = useState(false);
  const [activeLanguage,setActiveLanguage] = useState<string>('');
  const history = useHistory();
  const { appKey } = CheckKeyOfApp();
  const { updateAppKey } = UpdateKeyOfApp();
  const [dailyRateTxt, setDailyRateTxt] = useState('0');
  const [nightlyRateTxt, setNightlyRateTxt] = useState('0');
  const [injectionRateTxt, setInjectionRateTxt] = useState('0');
  const [numberAdultsTxt, setNumberAdultsTxt] = useState('0');
  const [numberChildrenTxt, setNumberChildrenTxt] = useState('0');

  const [showToast, setShowToast] = useState(false);
  const [showToastTxt, setShowToastTxt] = useState<string>('');
  const [userTxt, setUserTxt] = useState<string>('');
  const [dongleId,setDongleId] = useState<string>('');

  const [showLoading, setShowLoading] = useState(false);

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          setActiveLanguage(value);
          //i18n.changeLanguage(value);
        }
        else {
          //i18n.changeLanguage('nl');
          setActiveLanguage('nl');
        }
      }
    );
    appKey(Constants.KEY_DAILY_RATE).then(
      value => {
        setDailyRateTxt(value);
      }
    );
    appKey(Constants.KEY_INJECTION_RATE).then(
      value => {
        setInjectionRateTxt(value);
      }
    );
    appKey(Constants.KEY_NIGHTLY_RATE).then(
      value => {
        setNightlyRateTxt(value);
      }
    );
    appKey(Constants.KEY_NUMBER_ADULTS).then(
      value => {
        setNumberAdultsTxt(value);
      }
    );
    appKey(Constants.KEY_NUMBER_CHILDREN).then(
      value => {
        setNumberChildrenTxt(value);
      }
    );
    appKey(Constants.KEY_EMAIL).then(
      value => {
        if (value == 'apple@it-idea.be') {
          setAppleUser(true);
        }
        setUserTxt(value);
      }
    );
    appKey(Constants.KEY_RATE_TYPE).then(
      value => {
        if (value == 'Dubbel') {
          setChecked(true);
        }
      }
    );
    appKey(Constants.KEY_DONGLE_ID).then(
      value => {
        setDongleId(" - " + value);
      }
    );
    
  });

  function doResetDongle() {
    updateAppKey(Constants.KEY_STATUS,Constants.STATUS_DONGLE);
    updateAppKey(Constants.KEY_STATUS_DONGLE,'0');
    history.replace('/setup/dongle');
  }

  function doResetUser() {
    updateAppKey(Constants.KEY_STATUS,Constants.STATUS_LOGIN);
    history.replace('/setup/email');
  }

  function changeActiveLanguage(str:string) {
    setActiveLanguage(str);
    updateAppKey(Constants.KEY_LANG,str);
    i18n.changeLanguage(str);
  }

  function handleDailyRateKey(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    }
  }

  function handleNightlyRateKey(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    }
  }

  function handleInjectionRateKey(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    }
  }

  function handleNumberAdultsKey(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    }
  }

  function handleNumberChildrenKey(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    }
  }

  function setDailyRateText(str:string) {
    setDailyRateTxt(str);
  }

  function setNightlyRateText(str:string) {
    setNightlyRateTxt(str);
  }

  function setInjectionRateText(str:string) {
    setInjectionRateTxt(str);
  }

  function setNumberAdultsText(str:string) {
    setNumberAdultsTxt(str);
  }

  function setNumberChildrenText(str:string) {
    setNumberChildrenTxt(str);
  }

  function doUpdateSettings() {
    var rate_type = 'Enkel';
    if (checked) {
      rate_type = 'Dubbel';
    }
    setShowLoading(true);
    doUpdate(dailyRateTxt,nightlyRateTxt,injectionRateTxt,numberAdultsTxt,numberChildrenTxt,rate_type);
  }

  function doUpdate(dailyRate:string,nightlyRate:string,injectionRate:string,numberAdults:string,numberChildren:string,rateType:string) {
    appKey(Constants.KEY_EMAIL).then(
      value => {
        var email  = value;
        const params = new URLSearchParams();
        params.append('client_id', Constants.API_CLIENT_ID);
        params.append('client_secret', Constants.API_CLIENT_SECRET);
        params.append('grant_type', Constants.API_GRANT_TYPE);

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }

        var json:any = { email: email };
        if (dailyRate != '') {
          json.electricity_price_day = dailyRate;
        }
        if (nightlyRate != '') {
          json.electricity_price_night = nightlyRate;
        }
        if (numberAdults != '') {
          json.number_of_children = numberAdults;
        }
        if (numberChildren != '') {
          json.number_of_adults = numberChildren;
        }
        if (rateType != '') {
          json.rate_type = rateType;
        }
        

        axios.post(Constants.API_URL_TOKEN, params, config)
        .then(
          res => {
            var result:any = res.data;
            var token = result.access_token;
            axios.post(
              Constants.API_URL_POST_USER, 
              JSON.stringify(json),
              { 
                headers: {
                  "Authorization" : `Bearer ${token}`,
                  'Content-Type' : 'application/json' 
                } 
              }
            )
            .then(
                res2 => {
                  var result2:any = res2.data;
                  if (result2.error) {
                    setShowToastTxt(t('settings.api_problem'));
                    setShowToast(true);
                    setShowLoading(false);
                  }
                  else {
                    setShowToastTxt(t('settings.updated'));
                    setShowToast(true);
                    setShowLoading(false);
                    if (dailyRate != '') {
                      updateAppKey(Constants.KEY_DAILY_RATE,dailyRate);
                    }
                    if (nightlyRate != '') {
                      updateAppKey(Constants.KEY_NIGHTLY_RATE,nightlyRate);
                    }
                    if (injectionRate != '') {
                      updateAppKey(Constants.KEY_INJECTION_RATE,injectionRate);
                    }
                    if (numberAdults != '') {
                      updateAppKey(Constants.KEY_NUMBER_ADULTS,numberAdults);
                    }
                    if (numberChildren != '') {
                      updateAppKey(Constants.KEY_NUMBER_CHILDREN,numberChildren);
                    }
                    if (rateType != '') {
                      updateAppKey(Constants.KEY_RATE_TYPE,rateType);
                    }
                  }
                })
            .catch((error2) => {
              setShowToastTxt(t('settings.api_problem'));
              setShowToast(true);
              setShowLoading(false);
            });
          }
        )
        .catch(
          error => {
            setShowToastTxt(t('settings.api_problem'));
            setShowToast(true);
            setShowLoading(false);
          }
        );
      }
    );
  }
  
  return (
    <IonPage>
      <IonContent class="settings">
        <IonLoading
          cssClass="flow-loading"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('settings.patience')}
        />
        <h1>{t('settings.title')}</h1>
        <IonList>
          <IonItemDivider>
            <IonLabel>{t('settings.rates')}</IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>{t('settings.nightly_rate')}</IonLabel>
            <IonToggle checked={checked} onIonChange={e => setChecked(e.detail.checked)} slot="end"></IonToggle>
          </IonItem>
          <IonItem className="input--small">
            <IonLabel>{t('settings.daily_rate')}</IonLabel>
            <IonLabel slot="end" className="input-symbol">€</IonLabel>
            <IonInput onKeyPress={e=> handleDailyRateKey(e.key)} onIonChange={e => setDailyRateText(e.detail.value!)} type="number" step=".01" min="0.01" value={ dailyRateTxt } slot="end"></IonInput>
          </IonItem>
          <IonItem hidden={!checked} className="input--small">
            <IonLabel>{t('settings.nightly_rate')}</IonLabel>
            <IonLabel slot="end" className="input-symbol">€</IonLabel>
            <IonInput onKeyPress={e=> handleNightlyRateKey(e.key)} onIonChange={e => setNightlyRateText(e.detail.value!)} type="number" step=".01" min="0.01" value={ nightlyRateTxt } slot="end"></IonInput>
          </IonItem>
          <IonItem className="input--small">
            <IonLabel>{t('settings.injection_rate')}</IonLabel>
            <IonLabel slot="end" className="input-symbol">€</IonLabel>
            <IonInput onKeyPress={e=> handleInjectionRateKey(e.key)} onIonChange={e => setInjectionRateText(e.detail.value!)} type="number" step=".01" min="0.01" value={ injectionRateTxt } slot="end"></IonInput>
          </IonItem>

          <IonItemDivider>
            <IonLabel>{t('settings.family')}</IonLabel>
          </IonItemDivider>
          <IonItem className="input--small">
            <IonLabel>{t('settings.adults')}</IonLabel>
            <IonInput onKeyPress={e=> handleNumberAdultsKey(e.key)} onIonChange={e => setNumberAdultsText(e.detail.value!)} type="number" value={ numberAdultsTxt } min="1" slot="end"></IonInput>
          </IonItem>
          <IonItem className="input--small">
            <IonLabel>{t('settings.children')}</IonLabel>
            <IonInput onKeyPress={e=> handleNumberChildrenKey(e.key)} onIonChange={e => setNumberChildrenText(e.detail.value!)} type="number" value={ numberChildrenTxt } min="0" slot="end"></IonInput>
          </IonItem>
          <IonItem lines="none" className="settings-button ion-text-center">
            <IonButton shape="round" size="small" onClick={() => doUpdateSettings()}>{t('settings.update')}</IonButton>
          </IonItem>

          <IonItemDivider>
            <IonLabel>{t('settings.app_settings')}</IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>{t('settings.language_choice')}</IonLabel>
            <IonSelect interface='action-sheet' value={activeLanguage} onIonChange={(ev) => changeActiveLanguage(ev.detail.value)}>
              <IonSelectOption value="nl">Nederlands</IonSelectOption>
              <IonSelectOption value="fr">Français</IonSelectOption>
              <IonSelectOption value="en">English</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonItemDivider>
            <IonLabel>{t('settings.reset')}</IonLabel>
          </IonItemDivider>
          <IonItem hidden={appleUser} lines="none" className="settings-button ion-text-center">
            <IonLabel position="stacked" className="ion-text-wrap">{t('settings.reset_dongle_connection')}</IonLabel>
            <IonButton shape="round" size="small" onClick={() => doResetDongle()}>{t('settings.reset_dongle')}</IonButton>
          </IonItem>
          <IonItem lines="none" className="settings-button ion-text-center">
            <IonLabel position="stacked" className="ion-text-wrap">{t('settings.reset_user_connection')} ({ userTxt} {dongleId})</IonLabel>
            <IonButton shape="round" size="small" onClick={() => doResetUser()}>{t('settings.logout')}</IonButton>
          </IonItem>
        </IonList>
        <p className="ion-text-center">
          <a href="https://www.flow-energy.be/sites/flow_energy/files/2021-11/Privacybeleid.pdf" target="_blank" className="settings-link">{t('settings.privacy_statements')}</a>
        </p>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={ showToastTxt }
          duration={1000}
          position="top"
          color="danger"
        />

      </IonContent>  

      <IonFooter>
        <IonTabBar>
            <IonTabButton tab="dashboard" href="/dashboard">
            <IonIcon icon={barChartOutline} />
              <IonLabel>{t('settings.dashboard')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="settings" href="/settings">
              <IonIcon icon={settingsOutline} />
              <IonLabel>{t('settings.settings')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="alarm" href="/alarm">
              <IonIcon icon={alarmOutline} />
              <IonLabel>{t('settings.alarm')}</IonLabel>
            </IonTabButton>
          </IonTabBar>
      </IonFooter>
    </IonPage>
  );
};

export default Settings;
