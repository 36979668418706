import { createStore } from 'redux'
import moment from 'moment';

// ACTION
// const SET_CHART = 'SET_CHART'

// function chartType(value) {
//   return {
//     type: SET_CHART,
//     value: value
//   }
// }

const yesterday = moment(new Date()).subtract(1, 'day').startOf('day').toDate()
const today = moment(new Date()).startOf('day').toDate()

const initialState = {
  energyType: 'electricity',
  chartType: 'day',
  isChart: false,
  currentDate: today,
  currentWeek: yesterday,
  currentMonth: yesterday,
  currentYear: yesterday,
  minValueDate: moment(new Date('November 01, 2020')).toDate(),
  markAlert: [
    new Date('Sat May 02 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)'),
    new Date('Fri May 14 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)')
  ],
  markInfo: [
    new Date('Sat May 05 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)'),
    new Date('Fri May 16 2021 00:00:00 GMT+0200 (heure d’été d’Europe centrale)')
  ],
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case 'SET_ENERGY_TYPE':
      return {
        ...state,
        energyType: action.payload
      }
    case 'SET_CHART_TYPE':
      return {
        ...state,
        chartType: action.payload
      }
    case 'IS_CHART':
      return {
        ...state,
        isChart: action.payload
      }
    case 'SET_CURRENT_DATE':
      return {
        ...state,
        currentDate: action.payload
      }
    case 'SET_CURRENT_WEEK':
      return {
        ...state,
        currentWeek: action.payload
      }
    case 'SET_CURRENT_MONTH':
      return {
        ...state,
        currentMonth: action.payload
      }
    case 'SET_CURRENT_YEAR':
      return {
        ...state,
        currentYear: action.payload
      }
    default: return state
  }
}

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
