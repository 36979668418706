import { warning, arrowForwardOutline, close } from 'ionicons/icons';
import React, { Component, useState } from 'react';
import axios from 'axios';
import { IonContent, IonToolbar, useIonViewDidEnter, useIonViewWillLeave, IonPage, IonTabBar, IonTabButton, IonIcon, IonItem, IonLabel, IonFooter, IonHeader, IonButton, IonModal, IonButtons } from '@ionic/react';
import store from '../../store/index';
import { App } from '@capacitor/app';
import { CheckKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';
import { useTranslation } from "react-i18next"

import Charts from '../../components/charts/Charts';
import RealTimeChart from '../../components/charts/RealTimeChart';
import { barChartOutline, settingsOutline, alarmOutline } from 'ionicons/icons';
import './Dashboard.scss';

const Dashboard: React.FC = () => {

  const { t, i18n } = useTranslation();

  const [showHint, setShowHint] = useState(false);
  const [showDashboard, setShowDashboard] = useState(true);
  const [showHintModal, setShowHintModal] = useState(false);
  const { appKey } = CheckKeyOfApp();

  const [hintTitle, setHintTitle] = useState<string>('');
  const [hintLinkTitle, setHintLinkTitle] = useState<string>('');
  const [hintText, setHintText] = useState<string>('');
  const [dongleId,setDongleId] = useState<string>('');
  const [slug,setSlug] = useState<string>('');
  const [language,setLanguage] = useState<string>('EN');

  App.addListener('appStateChange', ({ isActive }) => {
    if (isActive) {
      refreshPage();
    }
    else {
      emptyPage();
    }
  });

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
          setLanguage(value.toUpperCase());
        }
        else {
          i18n.changeLanguage('nl');
          setLanguage("NL");
        }
      }
    );

    appKey(Constants.KEY_DONGLE_ID).then(
      value => {
        setDongleId(value);
        appKey(Constants.KEY_SLUG).then(
          value2 => {
            setSlug(value2);
            axios.get(Constants.API_URL_HINTDATA + '?id=' + dongleId + '&slug=' + slug)
            .then(
              res => {
                setShowHint(true);
                var result:any = res.data;
                setHintTitle(result.data.Title);
                setHintLinkTitle(result.data.LinkTitle);
                setHintText(result.data.Text);
              }
            )
            .catch(
              error => {
                console.log(error);	
              }
            );
          }
        );
      }
    );
    setShowDashboard(true);
    
  });

  useIonViewWillLeave(() => {
    emptyPage();
  });

  function refreshPage() {
    setShowDashboard(true);
  }  

  function emptyPage() { 
    setShowHint(false);
    setShowDashboard(false);
  }

  function showHintPopup() {
    setShowHintModal(true);
  }

  function hideHintPopup() {
    setShowHintModal(false);
  }

  return (
    <IonPage>
      <IonContent className="dashboard ion-no-padding">

        { showHint ? (
            <IonItem button onClick={() => showHintPopup()} className="hint" color="dark" lines="none">
              <IonIcon icon={warning} color="secondary" slot="start"></IonIcon>
              <IonLabel class="ion-text-wrap">
                <strong>{ hintTitle}</strong>
                <div>{ hintLinkTitle }</div>
              </IonLabel>
              <IonIcon color="light" icon={arrowForwardOutline} slot="end"></IonIcon>
            </IonItem>

        ) : ('') }  

        { showDashboard ? (
          <RealTimeChart
            t={t}
          />
        ) : ('') }  

        { showDashboard ? (  
          <Charts
            date={store.getState().currentDate}
            week={store.getState().currentWeek}
            month={store.getState().currentMonth}
            year={store.getState().currentYear}
            t={t}
            language={language}
          />
        ) : ('') }  

        <IonModal isOpen={showHintModal} cssClass='my-custom-class'>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => hideHintPopup()} color="dark"><IonIcon icon={close}></IonIcon></IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <p>{ hintText }</p>
          </IonContent>
        </IonModal>

      </IonContent>
      <IonFooter>
        <IonTabBar>
          <IonTabButton tab="dashboard" href="/dashboard">
            <IonIcon icon={barChartOutline} />
            <IonLabel>{t('dashboard.dashboard')}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="settings" href="/settings">
            <IonIcon icon={settingsOutline} />
            <IonLabel>{t('dashboard.settings')}</IonLabel>
          </IonTabButton>
          <IonTabButton tab="alarm" href="/alarm">
            <IonIcon icon={alarmOutline} />
            <IonLabel>{t('dashboard.alarm')}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonFooter>
    </IonPage>
  );
  
}

export default Dashboard;
