import { arrowForwardOutline } from 'ionicons/icons';
import { IonContent, IonPage, IonButton, IonIcon, useIonViewDidEnter } from '@ionic/react';
import './Dongle.scss';
import { useHistory } from "react-router-dom";
import { Browser } from '@capacitor/browser';
import { useTranslation } from "react-i18next"
import { CheckKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';

const Dongle: React.FC = () => {

  const { t, i18n } = useTranslation();

  const history = useHistory();
  const { appKey } = CheckKeyOfApp();

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
  });

  function whereToGoFromHere() {
    history.replace('/setup/wifi');
  }

  function goToBrowser() {
    Browser.open({ url: 'https://klanten.b2clogin.com/klanten.onmicrosoft.com/b2c_1a_customer_signup_signin/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20profile&client_id=91bb9a0a-f45d-491a-ae0b-43324fbc343a&redirect_uri=https%3A%2F%2Fmijn.fluvius.be&state=eyJpZCI6ImE4MTlmNWRjLTk2YjAtNGUxMi1hOTI0LWUzOGU4ZTkyYTk3NyIsInRzIjoxNjM3NzYyOTg4LCJtZXRob2QiOiJyZWRpcmVjdEludGVyYWN0aW9uIn0%3D&nonce=95785e11-df8a-43e3-9aa2-68d1485a64e9&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=msal&client-request-id=0109a633-7b87-48b2-a5ae-4050d03b3da4&response_mode=fragment' });
  }

  return (
    <IonPage>
      <IonContent slot="fixed" className="dongle">
        <div className="dongle-fixed" slot="fixed">
          <div className="dongle-content">
              <h1>{t('dongle.title')}</h1>
              <div className="dongle-text">
                <p>
                {t('dongle.info')}
                </p>
              </div>
              <a href="javascript:void(0);" onClick={() => goToBrowser()} target="_blank" className="dongle-link">{t('dongle.activate')}</a>
              <div className="action action--bottom">
                <IonButton shape="round" expand="block" onClick={() => whereToGoFromHere()}>{t('dongle.ok')} <IonIcon color="light" icon={arrowForwardOutline}></IonIcon></IonButton>
              </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Dongle;
