import { DataSegment, _EMPTY_SEGMENT } from './DataSegment';
import { Overview } from './structures';
import * as VIEWTYPE from './constants';
import axios from 'axios';
import moment from 'moment'
import * as Constants from '../../../hooks/Constants';
export * as VIEWTYPE from './constants';

export class DataManager {
	constructor(caller, viewType, queryParams,email) {
		this.segmentRequest = viewType == VIEWTYPE.MONTH || viewType == VIEWTYPE.YEAR;
		
		this.processStart = moment();
		this.waitingStart = [];
		this.waitedTime = [];

		this.dataSegments = [];
		this.caller = caller;
		this.viewType = viewType;
		this.queryParams = queryParams;
		this.email = email;
		this.noData = true;

		this.QueryData();
	}
	SegmentRequest() {
		if (this.segmentRequest) {
			let UTCStartTime = this.queryParams.start.substring(0, this.queryParams.start.length - 1);
			let UTCEndTime = this.queryParams.end.substring(0, this.queryParams.end.length - 1);
			let fullSegmentStart = moment(UTCStartTime);
			let fullSegmentEnd = moment(UTCEndTime);
			let segmentsBaseTime = "hours";
			switch (this.viewType) {
				case VIEWTYPE.YEAR: segmentsBaseTime = 'months'; break;
				case VIEWTYPE.MONTH:
				case VIEWTYPE.WEEK: segmentsBaseTime = 'days'; break;
				case VIEWTYPE.DAY: segmentsBaseTime = 'hours'; break;
			}
			let segmentsCnt = fullSegmentEnd.diff(fullSegmentStart, segmentsBaseTime);
			if (segmentsCnt == 0) { segmentsCnt = 1; }
			//-----
			for (let i = 0; i < segmentsCnt; i++) {
				let segmentedParams = Object.assign({}, this.queryParams);
				segmentedParams.start = moment(fullSegmentStart).add(i, segmentsBaseTime).format("YYYY-MM-DDTHH:mm:ss") + "Z"
				segmentedParams.end = moment(fullSegmentStart).add(i + 1, segmentsBaseTime).format("YYYY-MM-DDTHH:mm:ss") + "Z"
				this.dataSegments.push(new DataSegment(this.dataSegments.length, segmentedParams, this.viewType));
			}
		}
		else
			this.dataSegments.push(new DataSegment(this.dataSegments.length, this.queryParams, this.viewType));
	}
	QueryData() {
		this.SegmentRequest();
		this.noData = true;
		this.dataSegments.forEach(async segment => {
			this.waitingStart[segment.id] = moment();
			//-----
			await new Promise(resolve => setTimeout(resolve, Math.random() * 20 * segment.id));		// To minimise spamming the API
			var url = Constants.API_URL_DATA;
			axios.get(url, { params: segment.queryParams })
				.then(res => this.ReceiveData(segment.id, res))
				.then(() => this.SegmentProcessed(segment.id))
				.catch(
					error => {
						console.log(error);	
						this.caller.showErrorMessage(error);
					}
				);
		});
	}
	ReceiveData(segmentID, res) {
		this.waitedTime[segmentID] = moment().diff(this.waitingStart[segmentID]);

		if (res.status == 200) {
			let dataStructureRecieved = res.data.data != undefined && res.data.data.electricityMeters != undefined;
			let dataReceived = dataStructureRecieved && res.data.data.electricityMeters.length > 0;
			let emptyDataReceived = !dataStructureRecieved || !dataReceived;
			if (dataReceived) {
				this.noData = false;
			}	
			let segmentToProcess = this.dataSegments[segmentID];
			segmentToProcess.SetData(emptyDataReceived ? null : res.data.data.electricityMeters[0].quarterHourlyEnergy);
			segmentToProcess.FormatData();
			// In case of segmented data, we have to manually cloture the computation, as time criteria change is never triggered
			if (this.segmentRequest) {
				segmentToProcess.ClotureCurrentComputation();
			}		
		}
		else {
			this.caller.showErrorMessage("Onbekende fout bij het ophalen van de informatie.");
		}
			
	}
	MergeSegments(mergeOnlyFinishedSegments) {
		let mergedSegments = new DataSegment(_EMPTY_SEGMENT, {}, this.viewType);
		for (let i = 0; i < this.dataSegments.length; i++)
			this.dataSegments[i].MergeInto(mergedSegments, mergeOnlyFinishedSegments);
		mergedSegments.ClotureOverview();
		return mergedSegments.computedData;
	}
	SegmentProcessed(segmentID) {
		let processesFinishedCnt = 0;
		this.dataSegments.forEach(segment => processesFinishedCnt += segment.processed ? 1 : 0);
		let processesFinished = processesFinishedCnt >= this.dataSegments.length;
			
		if (processesFinished) {
			/*if (this.noData) {
				console.log("geen data beschikbaar");
				this.caller.showErrorMessage("Nog geen data beschikbaar");
			}
			else {*/
				this.caller.updateData(this.MergeSegments());
			//}			
		}
	}
}
