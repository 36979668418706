import store from '../../store/index';
import * as VIEWTYPE from './DataManager/constants';
import './ChartsNav.scss';

import DatePicker from '../DatePicker'
import useVisible from '../../helpers/useVisible'
import { IonIcon, IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';
import { flash, flame } from 'ionicons/icons';

const ChartsNav = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  return (
    <div className="charts__nav--container" ref={ref}>
        <div className="charts__nav">
          <div className="charts__nav-wrapper">

            <div class="charts__nav--type">
              <IonSegment mode="ios" value={store.getState().energyType} onIonChange={(e) => props.onElectricityChanged(e.detail.value)}>
                <IonSegmentButton ref={props.electricityBtnRef} value="electricity" className="button">
                  <IonIcon icon={flash}></IonIcon>
                </IonSegmentButton>
                <IonSegmentButton value="gas" ref={props.gasBtnRef} className="button">
                  <IonIcon icon={flame}></IonIcon>
                </IonSegmentButton>
              </IonSegment>
            </div>

            <div className="charts__dateselect">
              <DatePicker
                ref={props.datePickerRef}
                formatDateSelect={props.formatDateSelect}
                currentDate={props.currentDate}
                currentWeek={props.currentWeek}
                currentMonth={props.currentMonth}
                currentYear={props.currentYear}
                view={store.getState().chartType}
                onDateSelected={props.onDateSelected}
                onIncDate={props.onIncDate}
                onDecDate={props.onDecDate}
                isVisible={isVisible}
                isDisabled={props.isDisabled}
                checkDisabled={props.checkDisabled}
                setIsVisible={setIsVisible}
                language={props.language}
                 />
            </div>
          </div>

          <div className="charts__nav--period">
            <div ref={props.navBtnRefs[VIEWTYPE.SLIDER]} id="btnsSlider" />
            <IonSegment mode="ios" value={store.getState().chartType} onIonChange={(e) => props.onViewtypeChanged(e.detail.value)}>              
              <IonSegmentButton mode="ios" ref={props.navBtnRefs[VIEWTYPE.DAY]} value="day" className="button day yellow"><IonLabel>{props.t('chartsnav.day')}</IonLabel></IonSegmentButton>
              <IonSegmentButton mode="ios" ref={props.navBtnRefs[VIEWTYPE.WEEK]} value="week" className="button week yellow"><IonLabel>{props.t('chartsnav.week')}</IonLabel></IonSegmentButton>
              <IonSegmentButton mode="ios" ref={props.navBtnRefs[VIEWTYPE.MONTH]} value="month" className="button month yellow"><IonLabel>{props.t('chartsnav.month')}</IonLabel></IonSegmentButton>
              <IonSegmentButton mode="ios" ref={props.navBtnRefs[VIEWTYPE.YEAR]} value="year" className="button year yellow"><IonLabel>{props.t('chartsnav.year')}</IonLabel></IonSegmentButton>
            </IonSegment>
          </div>

        </div>
    </div>
  );
};

export default ChartsNav;
