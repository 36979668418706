import i18n from "i18next"
import { initReactI18next } from "react-i18next"


import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationNL from './locales/nl/translation.json';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  nl: {
    translation: translationNL
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    //fallbackLng: "nl",
    //lng: "fr",
    //load: "currentOnly",
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n;