import { IonContent, IonPage, IonButton, IonList, IonItem, IonLabel, IonNote, useIonViewDidEnter, IonToast, IonLoading } from '@ionic/react';
import './DongleOk.scss';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BLE } from '@ionic-native/ble';
import { Capacitor } from '@capacitor/core';
import { CheckKeyOfApp, UpdateKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';
import { useTranslation } from "react-i18next"

const DongleOk: React.FC = () => {

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const [everythingOk, setEverythingOk] = useState(false);
  const [tryAgain, setTryAgain] = useState(false);
  const [infoTxt, setInfoTxt] = useState<string>('');
  const [dailyRateTxt, setDailyRateTxt] = useState<string>('');
  const [nightlyRateTxt, setNightlyRateTxt] = useState<string>('');

  const [showLoading, setShowLoading] = useState(true);

  const { appKey } = CheckKeyOfApp();
  const { updateAppKey } = UpdateKeyOfApp();

  var peripheralId = '';
  var wifiName = '';
  var wifiPw = '';
  var dongleId = '';
  var intervalId:any = '';
  var intervalCounter = 0;

  const [showToast, setShowToast] = useState(false);
  const [showToastTxt, setShowToastTxt] = useState<string>('');

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
    if(!Capacitor.isNativePlatform()) {
      setEverythingOk(true);
      setShowLoading(false);
    }  
    else {
        appKey(Constants.KEY_PERIPHERAL_ID).then(
          value => {
            peripheralId = value;
            appKey(Constants.KEY_WIFI).then(
              value2 => {
                wifiName = value2;
                appKey(Constants.KEY_WIFIPW).then(
                  value3 => {
                    wifiPw = value3;

                    appKey(Constants.KEY_DONGLE_ID).then(
                      value4 => {
                        dongleId = value4;
                        BLE.isConnected(peripheralId).then(
                          data => {
                            setInfoTxt(t('dongleok.write_to_dongle'));
                            writeCharsToDongle();
                          },
                          err => {
                            setInfoTxt(t('dongleok.connection_dongle_failed'));
                            setTryAgain(true);
                            setShowLoading(false);
                          }
                        );
    
                      }
                    );  

                  }
                );  
              }
            );
          }
        );
    }
    getRateInfo();
  });

  function getRateInfo() {
    appKey(Constants.KEY_DAILY_RATE).then(
      value => {
        setDailyRateTxt(value.replace('.',','));
        appKey(Constants.KEY_NIGHTLY_RATE).then(
          value2 => {
            setNightlyRateTxt(value2.replace('.',','));
          }
        );
      }
    );
  }

  function writeCharsToDongle() {
    var arrBuffWifiName = str2ab(wifiName);
    var arrBuffWifiPwd = str2ab(wifiPw);
    var arrBuffDongleId = str2ab(dongleId);
    //setShowLoading(true);
    BLE.write(peripheralId,Constants.SERVICE_UUID_WIFI_AP,Constants.CHARACTERISTIC_UUID_WIFI_AP,arrBuffWifiName).then(
      value => {
        BLE.write(peripheralId,Constants.SERVICE_UUID_WIFI_PWD,Constants.CHARACTERISTIC_UUID_WIFI_PWD,arrBuffWifiPwd).then(
          value2 => {
            BLE.write(peripheralId,Constants.SERVICE_UUID_P1_LABEL,Constants.CHARACTERISTIC_UUID_P1_LABEL,arrBuffDongleId).then(
              value3 => {
                setInfoTxt(t('dongleok.test_dongle'));
                intervalId = setInterval(() => {
                  readCharToCheckIfOk();
                },4000);
                
              },
              err => {
                setInfoTxt(t('dongleok.dongle_error'));
                setTryAgain(true);
                setShowLoading(false);
                BLE.disconnect(peripheralId);
              }  
            )
          },
          err => {
            setInfoTxt(t('dongleok.dongle_error'));
            setTryAgain(true);
            setShowLoading(false);
            BLE.disconnect(peripheralId);
          }  
        )
      },
      err => {
        setInfoTxt(t('dongleok.dongle_error'));
        setTryAgain(true);
        setShowLoading(false);
        BLE.disconnect(peripheralId);
      }  
    );
  }

  function readCharToCheckIfOk() {
    intervalCounter++;
    if (intervalCounter < 10) {
      intervalCounter++;
      BLE.read(peripheralId,Constants.SERVICE_UUID_DONGLE_STATUS,Constants.CHARACTERISTIC_UUID_DONGLE_STATUS).then(
        function(buffer) {
          
          var buff = new Uint8Array(buffer);
          var result = new TextDecoder().decode(buff);
  
          if (result == '1') {
            clearInterval(intervalId);
            updateAppKey(Constants.KEY_STATUS,Constants.STATUS_DASHBOARD);
            updateAppKey(Constants.KEY_STATUS_DONGLE,'1');
            setEverythingOk(true);
            setShowLoading(false);
            BLE.disconnect(peripheralId);
          }
          
        
        },
        function(err) {
          clearInterval(intervalId);
          setInfoTxt(t('dongleok.dongle_not_working'));
          setTryAgain(true);
          setShowLoading(false);
          BLE.disconnect(peripheralId);
      
        }
      )
    }
    else {
      setInfoTxt(t('dongleok.dongle_not_working'));
      setTryAgain(true);
      setShowLoading(false);
      updateAppKey(Constants.KEY_ACTIVE_PERIPHERAL_ID,peripheralId);
      //BLE.disconnect(peripheralId);
    }
  }

  function whereToGoFromHere() {
    history.replace('/dashboard');
  }

  function goBackToStartDongle() {
    history.replace('/setup/dongle');
  }

  function str2ab(str:string) {
    var buf = new ArrayBuffer(str.length);
    var bufView = new Uint8Array(buf);
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }

  return (
    <IonPage>
      <IonContent slot="fixed" className="dongle dongle--ok">
        <IonLoading
          cssClass="flow-loading"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('dongleok.patience')}
        />
        <div className="dongle-fixed" slot="fixed">
          { everythingOk ? (<div className="dongle-content">
            <img src="assets/img-check.svg" className="check-icon" width="56" height="50" alt="" />
            <h1>{t('dongleok.congratulations')}</h1>
            <div className="dongle-text">
              <p>
              {t('dongleok.dongle_ready')}
              </p>
              <p>
              {t('dongleok.dongle_extra_info')}
              </p>  
              <h2>{t('dongleok.rate_type')}</h2>
              <IonList>
                <IonItem>
                  <IonLabel>{t('dongleok.daily_rate')}:</IonLabel><IonNote color="dark" slot="end">€{ dailyRateTxt }</IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel>{t('dongleok.nightly_rate')}:</IonLabel><IonNote color="dark" slot="end">€{ nightlyRateTxt }</IonNote>
                </IonItem>
              </IonList>
            </div>
            <div className="action action--bottom">
              <IonButton shape="round" expand="block" onClick={() => whereToGoFromHere()}>{t('dongleok.to_dashboard')}</IonButton>
            </div>
          </div>) : (<div className="dongle-content">
            <h1>{t('dongleok.configuration_dongle')}</h1>
            <IonLabel className="message message--error">{ infoTxt }</IonLabel>
            { tryAgain ? (<div className="action action--bottom"><IonButton onClick={() => goBackToStartDongle()} shape="round" expand="block">{t('dongleok.try_again')}</IonButton></div>) : '' }
          </div>) }
        </div>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={ showToastTxt }
          duration={1000}
          position="top"
          color="danger"
        />

      </IonContent>
    </IonPage>
  );
};

export default DongleOk;
