import { Storage } from '@capacitor/storage';

export function CheckKeyOfApp() {

    const appKey = async(key:string): Promise<string> => {
        const { value } = await Storage.get({ key: key });
       return new Promise((resolve, reject) => {
            if (typeof value === 'string') {
                resolve(value);
            } else {
                resolve('')
            } 
        });
    };    

    return {
        appKey,
    }

}  


export function UpdateKeyOfApp() {

    const updateAppKey = async(key:string, value: string) => {
        await Storage.set({
            key: key,
            value: value,
        });
    };    

    return {
        updateAppKey,
    }
    
}  