import { IonContent, useIonViewDidEnter, IonPage } from '@ionic/react';
import './Home.css';
import { CheckKeyOfApp, UpdateKeyOfApp } from '../hooks/AppKey';
import * as Constants from '../hooks/Constants';
import React from 'react';
import { useHistory } from "react-router-dom";

const Home: React.FC = () => {

  const history = useHistory();
  const { appKey } = CheckKeyOfApp();
  const { updateAppKey } = UpdateKeyOfApp();

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_STATUS).then(value => handleStatus(value));    
  });

  function handleStatus(value:string) {
    if (value == '') {
      value = Constants.STATUS_WELCOME;
      updateAppKey(Constants.KEY_STATUS,value);
    }
    if (value == Constants.STATUS_DASHBOARD) {
      history.replace('/dashboard');
    }
    else if (value == Constants.STATUS_LOGIN) {
      history.replace('/setup/email');
    }
    /*else if (value == Constants.STATUS_NOTIFICATIONS) {
      history.replace('/setup/notifications');
    }*/
    else if (value == Constants.STATUS_DONGLE) {
      history.replace('/setup/dongle');
    }
    else {
      history.replace('/setup/welcome');
    }
  }
   

  return (
    <IonPage>
        <IonContent fullscreen>
          
        </IonContent>
      </IonPage>
  );
};

export default Home;


