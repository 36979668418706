import { IonContent, useIonViewDidEnter, IonItemDivider, IonModal, IonButton, IonToolbar, IonButtons, IonHeader, IonPage, IonTabBar, IonTabButton, IonIcon, IonLabel, IonFooter, IonList, IonItem, IonToggle, IonInput, IonToast, IonLoading } from '@ionic/react';
import { useState } from 'react';
import { CheckKeyOfApp, UpdateKeyOfApp } from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';
import { barChartOutline, settingsOutline, alarmOutline, informationCircleOutline, close } from 'ionicons/icons';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import axios from 'axios';
import './Alarm.scss';
import { useTranslation } from "react-i18next"

const Alarm: React.FC = () => {

  const { t, i18n } = useTranslation();

  const [numberAlarmActive, setNumberAlarmActive] = useState(false);
  const [numberAlarmTxt, setNumberAlarmTxt] = useState('2.5');
  const [numberInjectionActive, setNumberInjectionActive] = useState(false);
  const [numberInjectionValue, setNumberInjectionValue] = useState('0.5');
  const [numberInjectionAmount, setNumberInjectionAmount] = useState('1');

  const [showToast, setShowToast] = useState(false);
  const [showToastTxt, setShowToastTxt] = useState<string>('');
  const [showLoading, setShowLoading] = useState(false);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoTxt, setShowInfoTxt] = useState<string>('');

  const [dongleId, setDongleId] = useState<string>('');

  const { appKey } = CheckKeyOfApp();
  const { updateAppKey } = UpdateKeyOfApp();

  const nullEntry: any[] = []
  const [notifications, setnotifications] = useState(nullEntry);

  useIonViewDidEnter(() => {

    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );

    appKey(Constants.KEY_DONGLE_ID).then(
      value => {
        setDongleId(value);
        appKey(Constants.KEY_SLUG).then(
          value2 => {

            axios.get(Constants.API_URL_NOTIFICATIONS_DATA + '?dongle_id=' + value + '&slug=' + value2)
            .then(
              res => {
                var data:any = res.data;
                if (data.data.user.peak_alarm == '1') {
                  setNumberAlarmActive(true);
                }
                if (data.data.user.peak_value != '') {
                  setNumberAlarmTxt(data.data.user.peak_value);
                }
                if (data.data.user.injection_alarm == '1') {
                  setNumberInjectionActive(true);
                }
                if (data.data.user.injection_value != '') {
                  setNumberInjectionValue(data.data.user.injection_value);
                }
                if (data.data.user.injection_amount != '') {
                  setNumberInjectionAmount(data.data.user.injection_amount);
                }
              }
            )
            .catch(
              error => {
                console.log(error);	
              }
            );

            axios.get(Constants.API_URL_HINTDATA + '?id=' + value + '&slug=' + value2)
            .then(
              res => {
                var data:any = res.data;
                setShowInfoTxt(data.data.Text + " " + data.data.AvgText);
              }
            )
            .catch(
              error => {
                console.log(error);	
              }
            );
          }
        );
      }
    );
    
  });

  function handleNumberAlarmTxt(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    }
  }

  function handleNumberInjectionValue(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    }
  }

  function handleNumberInjectionAmount(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    }
  }

  function doUpdateSettings() {
    PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
                setShowToastTxt(t('alarm.permission_denied'));
                setShowToast(true);
                setNumberAlarmActive(false);
                setNumberInjectionActive(false);
            }
            else {
                //setShowToastTxt('Push Notification permission granted');
                //setShowToast(true);
                register();
            }
          });
        }
        else {
          register();
        }
    });
  }

  function showInfoPopup() {
    setShowInfoModal(true);
  }

  function hideInfoPopup() {
    setShowInfoModal(false);
  }

  const register = () => {
    setShowLoading(true);

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token: Token) => {
            var varToken = token.value;

            var json:any = { token: varToken };
            json.peak_alarm = numberAlarmActive;
            json.peak_value = numberAlarmTxt;
            json.injection_alarm = numberInjectionActive;
            json.injection_value = numberInjectionValue;
            json.injection_amount = numberInjectionAmount;
            json.dongle_id = dongleId;
        
            axios.post(
              Constants.API_URL_NOTIFICATIONS_DATA, 
              JSON.stringify(json),
            )
            .then(
                res2 => {
                  var result2:any = res2.data;
                  setShowToastTxt(t('alarm.updated'));
                  setShowToast(true);
                  setShowLoading(false);
                })
            .catch((error2) => {
              setShowToastTxt(t('alarm.api_problem'));
              setShowToast(true);
              setShowLoading(false);
            });
          
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error: any) => {
          setShowLoading(false);
          setShowToastTxt(t('alarm.push_success') + ' ' + JSON.stringify(error));
          setShowToast(true);
        }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
            setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
        }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
            setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
        }
    );
}

  return (
    <IonPage>
      <IonContent class="settings">
        <IonLoading
          cssClass="flow-loading"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('alarm.patience')}
        />
        <IonList>
          <IonItemDivider>
            <IonLabel>{t('alarm.title')}</IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>{t('alarm.alarm_top')}</IonLabel>
            <IonToggle checked={numberAlarmActive} onIonChange={e => setNumberAlarmActive(e.detail.checked)} slot="end"></IonToggle>
          </IonItem>
          <IonItem hidden={!numberAlarmActive} className="input--small">
            <IonLabel>{t('alarm.from_kw')} </IonLabel>
            <IonIcon icon={informationCircleOutline} onClick={() => showInfoPopup()} />
            <IonLabel slot="end" className="input-symbol"></IonLabel>
            <IonInput onKeyPress={e=> handleNumberAlarmTxt(e.key)} onIonChange={e => setNumberAlarmTxt(e.detail.value!)} type="number" step=".1" min="2.5" value={ numberAlarmTxt } slot="end"></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel>{t('alarm.alarm_injection')}</IonLabel>
            <IonToggle checked={numberInjectionActive} onIonChange={e => setNumberInjectionActive(e.detail.checked)} slot="end"></IonToggle>
          </IonItem>
          <IonItem hidden={!numberInjectionActive} className="input--small">
            <IonLabel>{t('alarm.from_kw')}</IonLabel>
            <IonLabel slot="end" className="input-symbol"></IonLabel>
            <IonInput onKeyPress={e=> handleNumberInjectionValue(e.key)} onIonChange={e => setNumberInjectionValue(e.detail.value!)} type="number" step=".5" min="0.5" value={ numberInjectionValue } slot="end"></IonInput>
          </IonItem>
          <IonItem hidden={!numberInjectionActive} className="input--small">
            <IonLabel>{t('alarm.alarm_max')}</IonLabel>
            <IonLabel slot="end" className="input-symbol"></IonLabel>
            <IonInput onKeyPress={e=> handleNumberInjectionAmount(e.key)} onIonChange={e => setNumberInjectionAmount(e.detail.value!)} type="number" step="1" min="1" value={ numberInjectionAmount } slot="end"></IonInput>
          </IonItem>
          <IonItem lines="none" className="settings-button ion-text-center">
            <IonButton shape="round" size="small" onClick={() => doUpdateSettings()}>{t('alarm.update')}</IonButton>
          </IonItem>
        </IonList>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={ showToastTxt }
          duration={1000}
          position="top"
          color="danger"
        />

          <IonModal isOpen={showInfoModal} cssClass='my-custom-class'>
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => hideInfoPopup()} color="dark"><IonIcon icon={close}></IonIcon></IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <p>{infoTxt}</p>
            </IonContent>
          </IonModal>

      </IonContent>
      <IonFooter>
        <IonTabBar>
            <IonTabButton tab="dashboard" href="/dashboard">
            <IonIcon icon={barChartOutline} />
              <IonLabel>{t('alarm.dashboard')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="settings" href="/settings">
              <IonIcon icon={settingsOutline} />
              <IonLabel>{t('alarm.settings')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="alarm" href="/alarm">
              <IonIcon icon={alarmOutline} />
              <IonLabel>{t('alarm.alarm')}</IonLabel>
            </IonTabButton>
          </IonTabBar>
      </IonFooter>
    </IonPage>
  );
};

export default Alarm;
