import { round } from '../../../helpers/helpers';

export class FormatedData {
	constructor(timestampStart) {
		this.offtakeValue = 0;
		this.gasValue = 0;
		this.injection = 0;
		this.injectionValue = 0;
		this.averageValue = 0;
		this.timestampStart = timestampStart;
		this.timestampEnd = null;
	}
}

export class Overview {
	constructor(emptyOverview = false) {
		this.emptyOverview = emptyOverview;
		this.offtakeTotal = 0;
		this.gasTotal = 0;
		this.offtakeTotalPrice = 0;
		this.injectionTotal = 0;
		this.injectionDayNight = { day: 0, night: 0 };
		this.offtakeDayNight = { day: 0, night: 0 };
		this.consoDayNight = { day: 0, night: 0 };
		this.higher = 0;
	}
}

export class ComputedData {
	constructor(segmentID, timestamp = null) {
		this.segmentID = segmentID;
		this.data = [];
		this.overview = new Overview(false);
		//-----
		if (timestamp != null)
			this.ProcessNewData(timestamp);
	}
	ProcessNewData(timestamp) { this.data.push(new FormatedData(timestamp)); }
	ProcessedData() { return this.data[this.data.length - 1]; }
	ImportStatement(statement, dayTime) {
		let processedData = this.ProcessedData();
		let curStatementOfftakeValue = statement.offtakeValue ? statement.offtakeValue : 0;
		let curStatementInjectionValue = statement.injectionValue ? statement.injectionValue : 0;
		let curStatementGasValue = statement.gasValue ? statement.gasValue : 0;
		//-----
		processedData.offtakeValue += curStatementOfftakeValue;
		processedData.injection += curStatementInjectionValue;
		processedData.timestampEnd = statement.timestampEnd;
		processedData.gasValue += curStatementGasValue;
		//-----
		this.overview.injectionDayNight[dayTime] -= curStatementInjectionValue;
		this.overview.injectionTotal -= curStatementInjectionValue;
		this.overview.offtakeDayNight[dayTime] += curStatementOfftakeValue;
		this.overview.offtakeTotal += curStatementOfftakeValue;
		this.overview.gasTotal += curStatementGasValue;
		this.overview.consoDayNight[dayTime] += curStatementOfftakeValue - curStatementInjectionValue;
		this.overview.higher = Math.max(curStatementOfftakeValue, this.overview.higher);
	}
	ClotureCurrentComputation(statement = null) {
		let computedData = this.ProcessedData()
		computedData.injectionValue = -computedData.injection;
		computedData.averageValue = (computedData.offtakeValue - computedData.injection) / 2;
		if (statement != null)    // Call from ClotureCurrentComputation --> no more data to process
			this.ProcessNewData(statement.timestampStart);
	}
	ClotureComputation(computedData) {
	}
	ClotureOverview() {
		this.ClotureCurrentComputation();
		//-----
		this.overview.offtakeTotal = round(this.overview.offtakeTotal);
		this.overview.gasTotal = round(this.overview.gasTotal);
		this.overview.injectionTotal = round(Math.abs(this.overview.injectionTotal));
		this.overview.injectionDayNight.day = round(Math.abs(this.overview.injectionDayNight.day));
		this.overview.injectionDayNight.night = round(Math.abs(this.overview.injectionDayNight.night));
		this.overview.offtakeDayNight.day = round(this.overview.offtakeDayNight.day);
		this.overview.offtakeDayNight.night = round(this.overview.offtakeDayNight.night);
		this.overview.consoDayNight.day = round(this.overview.consoDayNight.day);
		this.overview.consoDayNight.night = round(this.overview.consoDayNight.night);
		this.overview.higher = (this.overview.higher * 4).toFixed(2);   // Higher must be four times the higher statement value recieved (customer request)
	}
	MergeInto(otherComputedData) {
		otherComputedData.data = otherComputedData.data.concat(this.data);
		//-----
		let myOverviewData = this.overview;
		let otherOverviewData = otherComputedData.overview;
		otherOverviewData.offtakeTotal += myOverviewData.offtakeTotal;
		otherOverviewData.gasTotal += myOverviewData.gasTotal;
		otherOverviewData.injectionTotal += myOverviewData.injectionTotal;
		otherOverviewData.injectionDayNight.day += myOverviewData.injectionDayNight.day;
		otherOverviewData.injectionDayNight.night += myOverviewData.injectionDayNight.night;
		otherOverviewData.offtakeDayNight.day += myOverviewData.offtakeDayNight.day;
		otherOverviewData.offtakeDayNight.night += myOverviewData.offtakeDayNight.night;
		otherOverviewData.consoDayNight.day += myOverviewData.consoDayNight.day;
		otherOverviewData.consoDayNight.night += myOverviewData.consoDayNight.night;
		otherOverviewData.higher = Math.max(otherOverviewData.higher, myOverviewData.higher);
	}
}
