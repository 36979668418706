//constants.ts
export const KEY_STATUS = 'STATUS';
export const KEY_STATUS_DONGLE = 'STATUS_DONGLE';
export const KEY_PERIPHERAL_ID = 'PERIPHERAL_ID';
export const KEY_ACTIVE_PERIPHERAL_ID = 'ACTIVE_PERIPHERAL_ID';
export const KEY_WIFI = 'WIFI';
export const KEY_WIFIPW = 'WIFIPW';
export const KEY_P1_LABEL = 'P1LABEL';
export const KEY_EMAIL = 'EMAIL';
export const KEY_EAN = 'EAN';
export const KEY_SLUG = 'SLUG';
export const KEY_DONGLE_ID = 'DONGLE_ID';
export const KEY_NUMBER_ADULTS = 'NUMBER_ADULTS';
export const KEY_NUMBER_CHILDREN = 'NUMBER_CHILDREN';
export const KEY_DAILY_RATE = 'DAILY_RATE';
export const KEY_NIGHTLY_RATE = 'NIGHTLY_RATE'
export const KEY_INJECTION_RATE = 'INJECTION_RATE';
export const KEY_RATE_TYPE = 'RATE_TYPE';
export const KEY_LANG = 'LANG';
//export const NUMBER_ALARM_ACTIVE = 'NUMBER_ALARM_ACTIVE';
//export const NUMBER_ALARM_VALUE = 'NUMBER_ALARM_VALUE';
//export const NUMBER_INJECTION_ACTIVE = 'NUMBER_INJECTION_ACTIVE';
//export const NUMBER_INJECTION_VALUE = 'NUMBER_INJECTION_VALUE';
//export const NUMBER_INJECTION_AMOUNT = 'NUMBER_INJECTION_AMOUNT';

export const STATUS_WELCOME = 'WELCOME';
export const STATUS_LOGIN = 'LOGIN';
export const STATUS_NOTIFICATIONS = 'NOTIFICATIONS';
export const STATUS_DONGLE = 'DONGLE';
export const STATUS_DASHBOARD = 'DASHBOARD';

export const API_CLIENT_ID = '0cb10757-ac77-43b6-a210-53409da616fe';
export const API_CLIENT_SECRET = 'PhqDfHja9vgKg0cpw98eX57KEVvjMI';
export const API_GRANT_TYPE = 'client_credentials';
export const API_URL_TOKEN = 'https://flow-energy.be/oauth/token';
export const API_URL_CHECK_USER = 'https://flow-energy.be/api/check-user/';
export const API_URL_GET_USER = 'https://flow-energy.be/api/user/';
export const API_URL_POST_USER = 'https://flow-energy.be/api/user';
export const API_URL_DATA = 'https://ftcenergy.azurewebsites.net/api/getData';
export const API_URL_REQUEST_DATA = 'https://ftcenergy.azurewebsites.net/api/requestData';
export const API_URL_DATA_APPLE = 'https://old.flow-energy.be/api/fluvius/energy-test';
export const API_URL_DATA_REALTIME = 'https://ftcenergy.azurewebsites.net/api/getRealTimeData';
export const API_URL_HINTDATA = 'https://ftcenergy.azurewebsites.net/api/getHintData';
export const API_URL_NOTIFICATIONS_DATA = 'https://ftcenergy.azurewebsites.net/api/user';

// export const API_CLIENT_ID = '1beaf521-3eea-4db4-9119-f03114fbe190';
// export const API_CLIENT_SECRET = 'PhqDfHja9vgKg0cpw98eX57KEVvjMI';
// export const API_GRANT_TYPE = 'client_credentials';
// export const API_URL_TOKEN = 'https://staging.hannibal.be/flow-energy/web/oauth/token';
// export const API_URL_CHECK_USER = 'https://staging.hannibal.be/flow-energy/web/api/check-user/';
// export const API_URL_GET_USER = 'https://staging.hannibal.be/flow-energy/web/api/user/';
// export const API_URL_POST_USER = 'https://staging.hannibal.be/flow-energy/web/api/user';
// export const API_URL_DATA = 'https://ftcenergy.azurewebsites.net/api/getData';
// export const API_URL_REQUEST_DATA = 'https://ftcenergy.azurewebsites.net/api/requestData';
// export const API_URL_DATA_APPLE = 'https://old.flow-energy.be/api/fluvius/energy-test';
// export const API_URL_DATA_REALTIME = 'https://ftcenergy.azurewebsites.net/api/getRealTimeData';
// export const API_URL_HINTDATA = 'https://ftcenergy.azurewebsites.net/api/getHintData';

export const CHARACTERISTIC_UUID_WIFI_AP = 'BEB5483E-36E1-4688-B7F5-EA07361B26A8';
export const SERVICE_UUID_WIFI_AP = '4FAFC201-1FB5-459E-8FCC-C5C9C331914B';
export const CHARACTERISTIC_UUID_WIFI_PWD = 'BEB5483E-36E1-4688-B7F5-EA07361B26A9';
export const SERVICE_UUID_WIFI_PWD = '4FAFC201-1FB5-459E-8FCC-C5C9C331914B';
export const CHARACTERISTIC_UUID_P1_LABEL = 'BEB5483E-36E1-4688-B7F5-EA07361B26AA';
export const SERVICE_UUID_P1_LABEL = '4FAFC201-1FB5-459E-8FCC-C5C9C331914B';
export const CHARACTERISTIC_UUID_LOG = 'BEB5483E-36E1-4688-B7F5-EA07361B26AB';
export const SERVICE_UUID_LOG = '4FAFC201-1FB5-459E-8FCC-C5C9C331914B';
export const CHARACTERISTIC_UUID_WIFIS = 'BEB5483E-36E1-4688-B7F5-EA07361B26AC';
export const SERVICE_UUID_WIFIS = '4FAFC201-1FB5-459E-8FCC-C5C9C331914B';
export const CHARACTERISTIC_UUID_DONGLE_STATUS = 'BEB5483E-36E1-4688-B7F5-EA07361B26AD';
export const SERVICE_UUID_DONGLE_STATUS = '4FAFC201-1FB5-459E-8FCC-C5C9C331914B';
