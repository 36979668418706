//import { RealTimeDataManager } from './DataManager/RealTimeDataManager';
import './RealTimeChart.scss';
import axios from 'axios';
import React, { Component } from 'react';
import { Line, Chart } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import StreamingPlugin from 'chartjs-plugin-streaming';
import { CSSTransition } from "react-transition-group";
import * as Constants from '../../hooks/Constants';
import { CheckKeyOfApp } from '../../hooks/AppKey';

Chart.register(StreamingPlugin);

class RealtimeChart extends Component {
  constructor(props) { super(props); }
  shouldComponentUpdate(nextProps, nextState) { return nextProps.update == "true"; }
  render() { return (<Line ref={this.props.passingref} {...this.props} />); }
}

class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      min: 0,
      max: 0,
      chartinterval: 0,
      error: false,
      current_use: 0,
      intervalId: 0,
    }  

    this.chartsElem = React.createRef();
    this.dongle_id = '';
    this.slug = '';

  }

  componentDidMount() {
    const { appKey } = CheckKeyOfApp();
    appKey(Constants.KEY_DONGLE_ID).then(
      value => {
        this.dongle_id = value;
        appKey(Constants.KEY_SLUG).then(
          value2 => {
            this.slug = value2;
            this.getData(); 
            let intervalId = setInterval(() => {
              this.getData();
            },15000);
            this.setState({
              intervalId: intervalId,
            });
          }
        );
      }
    );
  }

  componentWillUnmount() {
    this.chart.destroy();
    clearInterval(this.state.intervalId);
    this.setState({
      chartinterval: 0,
      max: 0,
    });
  }

  getData() {
    axios.get(Constants.API_URL_DATA_REALTIME + '?id=' + this.dongle_id + '&slug=' + this.slug)
				.then(
                    res => {
                        this.computeData(res.data);
                    }    
                )
				.catch(
					error => {
						console.log("error in realtime api: " + error);
            if (error.response == undefined) {
							this.showErrorMessage("Ongekende fout bij het ophalen van de informatie.");
						}
						else if (error.response.status == 429) {
							this.showErrorMessage("De API is bezet. Ververs deze pagina over een paar seconden.");
						}
						else {
							this.showErrorMessage("Onbekende API-fout.");
						}
					});
  }

  computeData(view) {
    this.chart = Chart.getChart(this.chartsElem.current);
    if (view.data === undefined || view.data[0] === undefined) {
      this.showErrorMessage("Nog even geduld. Duurt dit langer dan 3 minuten, probeer dan de Dongle te resetten via de Instellingen. Let op: het activeren van de P1 poort via Fluvius duurt normaal 15 minuten, maar kan tot 3 dagen duren.");
    }
    else {
      this.showErrorMessage(false);
      var current_use = Math.round(view.data[0].Consumption);
  
      var max = 0;
      var min = 0;
      var limit = 0;
      var stepSize = 200;
      var abs_use = Math.abs(current_use);
      if (abs_use * 1.25 > 1000) {
        limit = Math.ceil(abs_use * 1.25 / 1000)*1000;
        stepSize = 1000;
      }
      else {
        limit = Math.ceil(abs_use * 1.25 / 100)*100;
        if (limit < 1000) {
          if (limit == 100) { max = 200; }
          else if (limit == 300) { limit = 400; }
          else if (limit == 500) { limit = 600; }
          else if (limit == 700) { limit = 800; }
          else if (limit == 900) { limit = 1000; }
        }
        else if (limit > 1000) {
          limit = Math.ceil(abs_use * 0.25 / 1000)*1000;
          stepSize = 1000;
        }
      }  
      if (current_use >= 0) {
        max = limit;
        min = this.state.min;
      } 
      else {
        max = this.state.max;
        min = limit * -1;
      }

      if (this.state.chartinterval == 0) {
       
        this.chart.options.scales.y.max = max;
        this.chart.options.scales.y.min = min;
        this.chart.options.scales.y.stepSize = stepSize;
        this.chart.data.datasets.forEach(dataset => {
          dataset.data = [];
          //dataset.backgroundColor = this.setBarGradientColor(dataset.label,this.chart.chartArea);
        });
        this.chart.options.scales.x.realtime.pause = false;
        this.chart.update();
        
        this.setState({
          current_use: current_use,
          chartinterval: stepSize,
          max: max,
          min: min,
        });
      }
      else {
        if (this.state.max < max || this.state.min > min) {
          this.chart.options.scales.y.max = max;
          this.chart.options.scales.y.min = min;
          this.chart.options.scales.y.stepSize = stepSize;
          this.chart.update();

          this.setState({
            current_use: current_use,
            chartinterval: stepSize,
            max: max,
            min: min,
          });
        }
        else {
          this.setState({
            current_use: current_use,
          });
        }
      }
    }
  }

  updateChartData(chart) {
    chart.data.datasets.forEach(dataset => {
      dataset.data.push({
        x: Date.now(),
        y: 300
      });
      console.log(dataset.data.length);
    });
  }

  showErrorMessage(error) {
    this.setState(
      { 
        error: error,
      }
    );
  }

  render() {
    return (
      <div className="charts">

        <h1>{this.props.t('realtimechart.today')}</h1>
        <h2><strong>{ this.state.current_use } W</strong></h2>
        <div className="charts__container">

          {this.state.error && (
            <CSSTransition
              in={true}
              timeout={300}
              classNames="anim__fade"
              unmountOnExit
              appear>
              <div className="error">{this.state.error}</div>
            </CSSTransition>)
          }

          <RealtimeChart
            passingref={this.chartsElem}
            data={{
              datasets: [
                {
                  label: 'Dataset Pos',
                  backgroundColor: 'rgba(247,174,165,1)',
                  borderColor: 'rgb(255, 99, 132)',
                  borderDash: [],
                  fill: true,
                  data: [],
                  borderWidth: 1,
                },
                {
                  label: 'Dataset Neg',
                  backgroundColor: 'rgba(255,237,208, 1)',
                  borderColor: 'rgb(255, 99, 132)',
                  borderDash: [],
                  fill: true,
                  data: [],
                  borderWidth: 1,
                },
              ]
            }}
            options={{
                //responsive: true,
                aspectRatio: window.innerWidth <= 568 ? 1 : 2,
                maintainAspectRatio: false,
                // borderSkipped: true,
                layout: {
                  padding: {
                    top: 20,
                    left: 20,
                    bottom: 10,
                    right: 20
                  }
                },
                elements: {
                  point:{
                      radius: 0
                  }
                },
                scales: {
                  y: {
                    //stacked: true,
                    ticks: {
                      font: {
                        size: 12,
                        family: "'Ubuntu', sans-serif",
                        weight: 100
                      },
                      color: '#B0BCC2',
                      padding: 10,
                      stepSize:200,
                      //beginAtZero: true
                      callback: function(value,index,values) {
                        return value + ' W';
                      }
                    },
                    title: {
                      display: false,
                      text: 'Consumptie',
                      color: '#B0BCC2',
                      font: {
                        size: 12,
                        family: "'Ubuntu', sans-serif",
                        weight: 700
                      },
                    },
                    grid: {
                      // zeroLineWidth: 1,
                      drawBorder: false,
                      color: "rgba(176, 188, 194, .2)"
                    },
                    min: 0,
                    max: 0,
                  },
                  x: {
                    type: 'realtime',
                    realtime: {
                      duration: 100000,
                      refresh: 1000,
                      delay: 0,
                      pause: true,
                      frameRate: 10,
                      onRefresh: chart => {
                        chart.data.datasets.forEach(dataset => {
                          if (dataset.label == 'Dataset Pos') {
                            if (this.state.current_use >= 0) {
                              dataset.data.push({
                                x: Date.now(),
                                y: this.state.current_use
                              });
                            }
                            else {
                              dataset.data.push({
                                x: Date.now(),
                                y: 0
                              });
                            }
                          } 
                          else {
                            if (this.state.current_use < 0) {
                              dataset.data.push({
                                x: Date.now(),
                                y: this.state.current_use
                              });
                            }
                            else {
                              dataset.data.push({
                                x: Date.now(),
                                y: 0
                              });
                            }
                          } 

                          
                        });
                      }          
                    },
                    stacked: true,
                    ticks: {
                      font: {
                        size: 12,
                        family: "'Ubuntu', sans-serif",
                        weight: 100
                      },
                      color: '#B0BCC2',
                      padding: 10,
                      // beginAtZero: true
                    },
                    grid: {
                      display: false,
                      drawBorder: false
                    },
                  }
                },
                plugins: {
                  legend: {
                    display: false, // Remove title label
                    align: 'end',
                    position: 'bottom',
                    labels: {
                      usePointStyle: true,
                      boxWidth: 6,
                      font: {
                        size: 12,
                        family: "'Ubuntu', sans-serif",
                        weight: 100
                      },
                      padding: 15,
                    },
                    onClick: function(event, legendItem) {
                      return
                    }
                  },
                  datalabels: {
                    color: '#36A2EB'
                  },
                }  
            }}
          />

        </div>

      </div>  
    );
  }
}

export default App;