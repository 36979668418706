import './ChartsOverview.scss';
import store from '../../store/index';

const ChartsOverview = (props) => {

  function setValue(type, time) {
    if (!props.overview || props.overview.emptyOverview)
      return '';
    else if (time)
      return props.overview[type][time].toLocaleString('nl-BE').replace('.', ',')
    else
      return props.overview[type].toLocaleString('nl-BE')
  }

  function calculatePrice(type) {
    if (!props.overview || props.overview.emptyOverview)
      return '';
    else
      return (props.overview[type] * props.rate).toLocaleString('nl-BE', {minimumFractionDigits: 2, maximumFractionDigits:2});
  }

  if (store.getState().energyType == 'electricity') {
    return (
      <div class="chart-prices">
        <div class="chart-price chart-price--up">
          <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' width='18' height='18' viewBox='0 0 512 512'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 244l144-144 144 144M256 120v292'/></svg>
          {setValue('injectionTotal', false)} kWh | €{calculatePrice('injectionTotal', false)}<br /><span class="price-per-kwh">(€{props.rate_injection.replace('.',',')} / kWh)</span>
        </div>
        <div class="chart-price chart-price--down">
          <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' width='18' height='18' viewBox='0 0 512 512'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 268l144 144 144-144M256 392V100'/></svg>
          {setValue('offtakeTotal', false)} kWh | €{calculatePrice('offtakeTotal', false)}<br /><span class="price-per-kwh">(€{props.rate.replace('.',',')} / kWh)</span>
        </div>
      </div>
    );
  }
  else {
    return (
      <div class="chart-prices">
        <div class="chart-price chart-price--down">
          <svg xmlns='http://www.w3.org/2000/svg' class='ionicon' width='18' height='18' viewBox='0 0 512 512'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 268l144 144 144-144M256 392V100'/></svg>
          {setValue('gasTotal', false)} kWh | €{calculatePrice('gasTotal', false)}<br /><span class="price-per-kwh">(€{props.rate.replace('.',',')} / kWh)</span>
        </div>
      </div>
    );
  }

}

export default ChartsOverview;
