import { useState } from 'react';
import { IonContent, IonList, IonPage, IonItem, IonLabel, IonInput, IonButton, useIonViewDidEnter } from '@ionic/react';
import './WifiPassword.scss';
import { useHistory } from "react-router-dom";
import { CheckKeyOfApp, UpdateKeyOfApp } from '../../hooks/AppKey';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import * as Constants from '../../hooks/Constants';
import { useTranslation } from "react-i18next"

const WifiPassword: React.FC = () => {

  const { t, i18n } = useTranslation();

  const [showError, setShowError] = useState(false);
  const [showErrorTxt, setShowErrorTxt] = useState<string>('');

  const [wifiValue, setWifiValue] = useState<string>('');

  const [passValue, setPassValue] = useState<string>('');

  const history = useHistory();
  const { appKey } = CheckKeyOfApp();
  const { updateAppKey } = UpdateKeyOfApp();

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
    appKey(Constants.KEY_WIFI).then(value => setWifiValue(value));    
  });

  function checkPassword() {
    if (!isEmpty(passValue)) {
      updateAppKey(Constants.KEY_WIFIPW,passValue);
      history.replace('/setup/dongleok');
    }
    else {
      setShowErrorTxt(t('wifipassword.empty_password'));
      setShowError(true);
    }
  }

  function setText(str:string) {
    setPassValue(str);
  }

  function isEmpty(str:string) {
    return (!str || str.length === 0 );
  }

  function handleKey(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }  
      checkPassword();
    }
  }

  return (
    <IonPage>
      <IonContent className="wifi wifi-password">
        <h1>{t('wifipassword.title')}</h1>
        { showError ? (<IonLabel className="message message--error ion-margin">{ showErrorTxt }</IonLabel>) : '' }
        <div className="wifi-list">
          <IonList>
            <IonItem lines="none">
              <IonLabel>{ wifiValue }</IonLabel>
            </IonItem>
          </IonList>
          <IonItem className="password" lines="none">
            <IonLabel position="stacked">{t('wifipassword.password')}:</IonLabel>
            <IonInput onKeyPress={e=> handleKey(e.key)} type="password" onIonChange={e => setText(e.detail.value!)}></IonInput>
          </IonItem>
          <div className="action action--bottom ion-padding-horizontal">
            <IonButton shape="round" expand="block" onClick={() => checkPassword()}>{t('wifipassword.connect_to_wifi')}</IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default WifiPassword;
