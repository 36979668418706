import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';
import Welcome from './pages/setup/Welcome';
import Gdpr from './pages/setup/Gdpr';
import LoginMail from './pages/setup/LoginMail';
import LoginPassword from './pages/setup/LoginPassword';
import Notifications from './pages/setup/Notifications';
import Dongle from './pages/setup/Dongle';
import Wifi from './pages/setup/Wifi';
import WifiPassword from './pages/setup/WifiPassword';
import DongleOk from './pages/setup/DongleOk';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/Settings';
import Alarm from './pages/alarm/Alarm';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/fonts.css';
import './theme/global.scss';

import './theme/content.scss';
import './theme/button.scss';
import './theme/checkbox.scss';
import './theme/input.scss';
import './theme/item.scss';
import './theme/list.scss';
import './theme/modal.scss';
import './theme/segment.scss';
import './theme/tabs.scss';

const App: React.FC = () => (

  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/" component={Home} />
        <Route exact path="/setup/welcome" component={Welcome} />
        <Route exact path="/setup/gdpr" component={Gdpr} />
        <Route exact path="/setup/email" component={LoginMail} />
        <Route exact path="/setup/password" component={LoginPassword} />
        <Route exact path="/setup/notifications" component={Notifications} />
        <Route exact path="/setup/dongle" component={Dongle} />
        <Route exact path="/setup/wifi" component={Wifi} />
        <Route exact path="/setup/wifipassword" component={WifiPassword} />
        <Route exact path="/setup/dongleok" component={DongleOk} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/alarm" component={Alarm} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
