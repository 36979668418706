import {ConsoBarOfftake, ConsoBarInjection, GasOfftake} from './config/ChartsConfig';
import * as VIEWTYPE from './DataManager/constants';
import { DataManager } from './DataManager/DataManager';
import { dateFormatApi, checkLimitDate } from '../../helpers/helpers'
import store from '../../store/index';
import { CheckKeyOfApp} from '../../hooks/AppKey';
import * as Constants from '../../hooks/Constants';

import ChartsNav from './ChartsNav';
import ChartsOverview from './ChartsOverview';
import { Fragment } from 'react';

import './Charts.scss';

import React, { Component } from 'react';
import { Bar, Chart  } from 'react-chartjs-2';
import moment from 'moment';
import momentNl from 'moment/locale/nl-be'
import { CSSTransition } from "react-transition-group";

class Chartsjs extends Component {
  
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      chartData: {},
      chartOverview: null,
      today: moment(new Date()).set({ "hour": 0, "minute": 0, "second": 0, "millisecond": 0 }),
      currentDate: this.props.date,
      currentWeek: this.props.week,
      currentMonth: this.props.month,
      currentYear: this.props.year,
      isDisabled: false,
      loading: false,
      min: 0,
      max: 0,
      error: false,
      chartoptions: {
        responsive: true,
        aspectRatio: window.innerWidth <= 568 ? 1 : 2,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 20,
            left: 20,
            bottom: 10,
            right: 20
          }
        },
        scales: {
          y: {
            stacked: true,
            ticks: {
              font: {
                size: 12,
                family: "'Ubuntu', sans-serif",
                weight: 100
              },
              color: '#B0BCC2',
              padding: 10,
            },
            title: {
              display: false,
              text: 'Injectie                                      Afname',
              color: '#B0BCC2',
              font: {
                size: 12,
                family: "'Ubuntu', sans-serif",
                weight: 700
              },
            },
            grid: {
              drawBorder: false,
              color: "rgba(176, 188, 194, .2)"
            },
          },
          x: {
            stacked: true,
            ticks: {
              font: {
                size: 12,
                family: "'Ubuntu', sans-serif",
                weight: 100
              },
              color: '#B0BCC2',
              padding: 10,
            },
            grid: {
              display: false,
              drawBorder: false
            },
          },
        },
        plugins: {
          legend: {
            display: false, // Remove title label
            align: 'end',
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 6,
              font: {
                size: 12,
                family: "'Ubuntu', sans-serif",
                weight: 100
              },
              padding: 15,
            },
            onClick: function(event, legendItem) {
              return
            }
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            padding: 12,
            external(tooltipModel) {
              let tooltipEl = document.getElementById('chartjs-tooltip');
              if (!tooltipEl) {
                let chart = document.getElementById('charts').parentElement;
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.classList.add('tooltip');
                chart.appendChild(tooltipEl);
              }

              if (tooltipModel.tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }
              let type = store.getState().chartType;
              if (type === 'year') {
                tooltipEl.innerHTML = "<h2>" + props.t("charts.month_" + tooltipModel.tooltip.dataPoints[0].dataIndex) + "</h2>";
              }
              else if (type === 'month') {
                tooltipEl.innerHTML = "<h2>" + (tooltipModel.tooltip.dataPoints[0].dataIndex + 1) + ' ' + moment(store.getState().currentMonth).locale('nl-be',momentNl).format("MMMM")+ "</h2>";
              }
              else if (type === 'week') {
                tooltipEl.innerHTML = "<h2>" + props.t("charts.day_" + tooltipModel.tooltip.dataPoints[0].dataIndex) + "</h2>";
              }
              else if (type === 'day') {
                let hour = tooltipModel.tooltip.dataPoints[0].dataIndex;
                tooltipEl.innerHTML = "<h2>" +  hour + props.t("charts.hour") + "-" + (hour + 1)  + props.t("charts.hour") + "</h2>";
              }

              if (store.getState().energyType == 'gas') {
                tooltipEl.innerHTML += "<div class='tooltip-value'><svg xmlns='http://www.w3.org/2000/svg' class='ionicon' width='18' height='18' viewBox='0 0 512 512'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 268l144 144 144-144M256 392V100'/></svg> " + GasOfftake.data[tooltipModel.tooltip.dataPoints[0].dataIndex].y.toLocaleString('nl-BE') + " kWh</div>";
              }
              else {
                tooltipEl.innerHTML += "<div class='tooltip-value'><svg xmlns='http://www.w3.org/2000/svg' class='ionicon' width='18' height='18' viewBox='0 0 512 512'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 268l144 144 144-144M256 392V100'/></svg> " + ConsoBarOfftake.data[tooltipModel.tooltip.dataPoints[0].dataIndex].y.toLocaleString('nl-BE') + " kWh</div>";
                tooltipEl.innerHTML += "<div class='tooltip-value'><svg xmlns='http://www.w3.org/2000/svg' class='ionicon' width='18' height='18' viewBox='0 0 512 512'><path fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 244l144-144 144 144M256 120v292'/></svg> " + Math.abs(ConsoBarInjection.data[tooltipModel.tooltip.dataPoints[0].dataIndex].y).toLocaleString('nl-BE') + " kWh</div>";
              }
              
              const {offsetLeft: positionX, offsetTop: positionY} = document.getElementById('charts');

              tooltipEl.style.opacity = 1;

              tooltipEl.style.left = positionX + tooltipModel.tooltip.caretX + 'px';
              tooltipEl.style.top = positionY + tooltipModel.tooltip.caretY + 'px';
              tooltipEl.style.font = tooltipModel.tooltip.options.bodyFont.string;
              tooltipEl.style.padding = tooltipModel.tooltip.options.padding + 'px ' + tooltipModel.tooltip.options.padding + 'px';
            }
          },
          datalabels: {
            color: '#36A2EB'
          },
        }
      },
    }

    this.rate = '0.4';
    this.rate_injection = '0.3';
    const { appKey } = CheckKeyOfApp();
      appKey(Constants.KEY_DAILY_RATE).then(
        value => {
          this.rate = value;
        }
      );
      appKey(Constants.KEY_INJECTION_RATE).then(
        value => {
          this.rate_injection = value;
        }
      );
      
    this.navBtnRefs = [];
    for (let i = 0; i <= VIEWTYPE.SLIDER; i++) {
      this.navBtnRefs.push(React.createRef());
    }
    this.electricityBtnRef = React.createRef();
    this.gasBtnRef = React.createRef();
    this.chartsElem = React.createRef();
    this.datePickerRef = React.createRef();

    this.formatDateSelect = this.formatDateSelect.bind(this);

  }

  componentDidMount() {
    this.getData(this.state.currentDate);
    var tooltipEl = document.getElementById('chartjs-tooltip');
    if (tooltipEl) {
      tooltipEl.style.opacity = 0;
    }  
  }

  componentWillUnmount() {
    Chart.getChart(this.chartsElem.current).destroy();
  }

  transformData(view, segmentId = null) {
    let offtake = null;
    let injection = null;

    offtake = view.data.map(obj => {
      let rObj = {}
      rObj['x'] = 0
      rObj['y'] = Math.abs(obj.offtakeValue)
      return rObj
    })    

    injection = view.data.map(obj => {
      let rObj = {}
      rObj['x'] = 0
      rObj['y'] = Math.min(obj.injectionValue, 0)
      return rObj
    })

    if (segmentId == null) {
      ConsoBarOfftake.data = offtake;
      ConsoBarOfftake.barPercentage = 0.6;

      ConsoBarInjection.data = injection;
      ConsoBarInjection.barPercentage = 0.6;

    } else {
      if (view.data.length > 0) {   // Case of empty columns received from Fluvius (shouldn't happen !)
        ConsoBarOfftake.data[segmentId] = offtake[0];
        ConsoBarInjection.data[segmentId] = injection[0];
      }
    }

    const datasets = []
    datasets.push(ConsoBarOfftake, ConsoBarInjection);

    return [datasets, offtake, injection];
  }

  transformDataGas(view, segmentId = null) {
    let gas = null;

    gas = view.data.map(obj => {
      let rObj = {}
      rObj['x'] = 0
      rObj['y'] = Math.abs(obj.gasValue, 0)
      return rObj
    })

    if (segmentId == null) {

      GasOfftake.data = gas;
      GasOfftake.barPercentage = 0.6;

    } else {
      if (view.data.length > 0) {   // Case of empty columns received from Fluvius (shouldn't happen !)
        GasOfftake.data[segmentId] = gas[0];
      }
    }

    const datasets = []
    datasets.push(GasOfftake);  

    return [datasets, gas];
  }


  updateData(view) {

    this.chart = Chart.getChart(this.chartsElem.current);

    if (store.getState().energyType == 'gas') {
      

      let [datasets, gas] = this.transformDataGas(view);
      let [labels, viewData] = this.setChartView(gas, datasets);
      
      this.setState({
        chartData: {
          labels: labels,
          datasets: viewData,
        },
        error: false,
        chartOverview: view.overview,
        loading: false, 
        max: Math.max(...gas.map(({ y }) => y)),
        min: 0
      });

    }
    else {
      let [datasets, offtake, injection] = this.transformData(view);
      let [labels, viewData] = this.setChartView(offtake, datasets);
      
      this.setState({
        chartData: {
          labels: labels,
          datasets: viewData,
        },
        error: false,
        chartOverview: view.overview,
        loading: false, 
        max: Math.max(...offtake.map(({ y }) => y)),
        min: Math.min(...injection.map(({ y }) => y))
      });
    }  
    
    this.toggleNavButtons(true);

  }

  toggleNavButtons(enable) {
    for (let i = 0; i < VIEWTYPE.SLIDER; i++) {
      if (this.navBtnRefs[i].current !== null) {
        this.navBtnRefs[i].current.disabled = !enable;
      }
    }
    this.electricityBtnRef.current.disabled = !enable;
    this.gasBtnRef.current.disabled = !enable;
  }

  showErrorMessage(error) {
    this.setState(
      { 
        loading: false, 
        error: error,
      }
    );
    this.toggleNavButtons(true);
  }
  
  CheckViewType() {
    switch (store.getState().chartType) {
      case 'year': return VIEWTYPE.YEAR;
      case 'month': return VIEWTYPE.MONTH;
      case 'week': return VIEWTYPE.WEEK;
      case 'day': return VIEWTYPE.DAY;
    }
  }

  getData = (currentDate) => {

    this.toggleNavButtons(false);
    this.date = dateFormatApi(currentDate);

    this.setState({ loading: true }, () => {
      var ean = '';
      var slug = '';
      var dongle_id = '';
      const { appKey } = CheckKeyOfApp();
      appKey(Constants.KEY_EAN).then(
        value => {
          ean = value;
          appKey(Constants.KEY_SLUG).then(
            value2 => {
              slug = value2;
              appKey(Constants.KEY_DONGLE_ID).then(
                value3 => {
                  dongle_id = value3;
                  appKey(Constants.KEY_EMAIL).then(
                    value4 => {
                      let viewType = this.CheckViewType();
                      let queryParams = {
                        start: this.date.from,
                        end: this.date.to, 
                        ean: ean,
                        slug: slug,
                        id: dongle_id,
                      };
                      new DataManager(this, viewType, queryParams,value4);
                    }
                  );    
                }
              );    
            }
          );
        }
      );
    });
  }

  cleanChart() {
    // Clean chart state data so previous data are not displayed while waiting for new data
    this.setState({ chartData: {}, error: "loading ..." });
  }

  setChartView = (offtake, data) => {

    let labels = [];
    let timeType = this.CheckViewType();

    for (let i = 1; i < offtake.length + 1; i++) {
      if (timeType == VIEWTYPE.YEAR)
        labels.push(moment(this.date.from).add(i - 1, 'month').format("MMM").substr(0,1));
      else if (timeType == VIEWTYPE.WEEK)
        labels.push(moment(this.props.week).startOf('isoWeek').add(i - 1, 'day').format("dd"));
      else if (timeType == VIEWTYPE.DAY) {
        if (i == 2 || i == 7) {
          labels.push('0' + i + '.00');
        } 
        else if (i == 12 || i == 17 || i == 22) {
          labels.push(i + '.00');
        }
        else {
          labels.push('');
        }
      }
      else {
        labels.push(i);
      }
        
    }

    return [labels, data];
  }

  setView = (type) => {
    const chartType = store.getState().chartType;
    if (chartType !== type) {
      store.dispatch({
          type: 'SET_CHART_TYPE',
          payload: type
      })

      this.cleanChart();
      this.toggleView(type)
    }

  }

  setEnergy = (type) => {

    store.dispatch({
      type: 'SET_ENERGY_TYPE',
      payload: type
    })

    const chartType = store.getState().chartType;

    this.cleanChart();
    this.toggleView(chartType)

  }

  onElectricityChanged(energy) {
    this.setEnergy(energy);
  }

  onViewtypeChanged(type) {
    this.setView(type);
  }

  toggleView = (type) => {
    switch (type) {
      case 'year': this.getData(this.state.currentYear); break;
      case 'month': this.getData(this.state.currentMonth); break;
      case 'week': this.getData(this.state.currentWeek); break;
      case 'day': this.getData(this.state.currentDate); break;
    }

    checkLimitDate(this.state.currentDate, this.state.currentWeek, this.state.currentMonth, this.state.currentYear, this, false);
  }

  changeDate(date) {
    this.cleanChart();
    this.getData(date);
  }

  setDate = (date) => {
    store.dispatch({
      type: 'SET_CURRENT_DATE',
      payload: date.date
    })

    this.setState({currentDate: date.date})
    this.changeDate(date.date);
  }

  setWeek = (date) => {
    store.dispatch({
      type: 'SET_CURRENT_WEEK',
      payload: date.date
    })

    this.setState({ currentWeek: date.date })
    this.changeDate(date.date);
  }

  setMonth = (date) => {
    store.dispatch({
      type: 'SET_CURRENT_MONTH',
      payload: date.date
    })

    this.setState({currentMonth: date.date})
    this.changeDate(date.date);
  }

  setYear = (date) => {
    store.dispatch({
      type: 'SET_CURRENT_YEAR',
      payload: date.date
    })

    this.setState({ currentYear: date.date })
    this.changeDate(date.date);
  }

  decDate = () => {
    if (!this.state.loading) {
      this.setState({loading: true})
      let decCurrent;
      switch (store.getState().chartType) {
        case 'year': decCurrent = moment(this.state.currentYear).subtract(1, 'year').toDate(); break;
        case 'month': decCurrent = moment(this.state.currentMonth).subtract(1, 'month').toDate(); break;
        case 'week': decCurrent = moment(this.state.currentWeek).subtract(1, 'week').toDate(); break;
        case 'day': decCurrent = moment(this.state.currentDate).subtract(1, 'day').toDate(); break;
      }

      const end = store.getState().chartType.toUpperCase();

      store.dispatch({
        type: 'SET_CURRENT_' + end,
        payload: decCurrent
      })

      switch (store.getState().chartType) {
        case 'year': this.setState({ currentYear: decCurrent }); break;
        case 'month': this.setState({ currentMonth: decCurrent }); break;
        case 'week': this.setState({ currentWeek: decCurrent }); break;
        case 'day': this.setState({ currentDate: decCurrent }); break;
      }

      this.checkDisabled(false);
      this.cleanChart();
      this.getData(decCurrent);

    }
  }

  incDate = () => {
    if (!this.state.loading) {
      this.setState({loading: true})

      const yesterday = moment(this.state.today).subtract(1, 'day').toDate();
      const today = moment(this.state.today).toDate();
      let incCurrent;
      switch (store.getState().chartType) {
        case 'year': incCurrent = moment(this.state.currentYear).add(1, 'year').toDate(); break;
        case 'month': incCurrent =  moment(this.state.currentMonth).add(1, 'month').toDate(); break;
        case 'week': incCurrent = moment(this.state.currentWeek).add(1, 'week').toDate(); break;
        case 'day': incCurrent = moment(this.state.currentDate).add(1, 'day').toDate(); break;
      }

      if (store.getState().chartType == 'day') {
        if (incCurrent.valueOf() <= today.valueOf()) {
         
          store.dispatch({
            type: 'SET_CURRENT_DATE',
            payload: incCurrent
          })

          this.setState({ currentDate: incCurrent })
  
          this.cleanChart();
          this.getData(incCurrent);
  
          if (incCurrent.valueOf() == today.valueOf()) {
            this.checkDisabled(true);
          }
  
        } else {
          this.cleanChart();
          this.checkDisabled(true);
        }
      }
      else {
        if (incCurrent.valueOf() <= yesterday.valueOf()) {
          switch (store.getState().chartType) {
            case 'year': {
              store.dispatch({
                type: 'SET_CURRENT_YEAR',
                payload: incCurrent
              })
              this.setState({ currentYear: incCurrent })
  
              break;
            }
            case 'month': {
              store.dispatch({
                type: 'SET_CURRENT_MONTH',
                payload: incCurrent
              })
              this.setState({ currentMonth: incCurrent })
  
              break;
            }
            case 'week': {
              store.dispatch({
                type: 'SET_CURRENT_WEEK',
                payload: incCurrent
              })
              this.setState({ currentWeek: incCurrent })
  
              break;
            }
          }
  
          this.cleanChart();
          this.getData(incCurrent);
  
          if (incCurrent.valueOf() == yesterday.valueOf()) {
            this.checkDisabled(true);
          }
  
        }else{
          this.cleanChart();
          this.checkDisabled(true);
        }
      }

    }
  }
  checkDisabled = (value) => {
    this.setState({isDisabled: value})
  }
  formatDateSelect() {
    const currentDate = this.state.currentDate;
    const currentWeek = this.state.currentWeek;
    const currentMonth = this.state.currentMonth;
    const currentYear = this.state.currentYear;
    switch (store.getState().chartType) {
      case 'year': return moment(currentYear).locale('nl-be', momentNl).format("YYYY");
      case 'month': return moment(currentMonth).locale('nl-be', momentNl).format("MMMM YYYY");
      case 'week': {
        let lastFullWeek = moment(currentWeek);
        let from = lastFullWeek.startOf('isoWeek').locale('nl-be').format("D MMM")
        let to = lastFullWeek.endOf('isoWeek').locale('nl-be').format("D MMM YYYY")
        return (<Fragment>{from} tot<br />{to}</Fragment>);
      }
      case 'day': return moment(currentDate).locale('nl-be').format("dd D MMM YYYY");
    }
  }

  render() {
    let onDateSelectedHandle;
    switch (store.getState().chartType) {
      case 'year': onDateSelectedHandle = this.setYear; break;
      case 'month': onDateSelectedHandle = this.setMonth; break;
      case 'week': onDateSelectedHandle = this.setWeek; break;
      case 'day': onDateSelectedHandle = this.setDate; break;
    }

    return (
      <div className="charts">
        <ChartsNav
          formatDateSelect={this.formatDateSelect}
          navBtnRefs={this.navBtnRefs}
          currentDate={this.state.currentDate}
          currentWeek={this.state.currentWeek}
          currentMonth={this.state.currentMonth}
          currentYear={this.state.currentYear}
          isDisabled={this.state.isDisabled}
          checkDisabled={this.checkDisabled}
          onDateSelected={onDateSelectedHandle}
          onViewtypeChanged={(e) => this.onViewtypeChanged(e)}
          onElectricityChanged={(e) => this.onElectricityChanged(e)}
          electricityBtnRef={this.electricityBtnRef}
          gasBtnRef={this.gasBtnRef}
          datePickerRef={this.datePickerRef}
          onIncDate={this.incDate}
          onDecDate={this.decDate}
          t={this.props.t}
          language={this.props.language} />

          {this.state.loading ?
            '' :
            <ChartsOverview
              rate={this.rate}
              rate_injection={this.rate_injection}
              overview={this.state.chartOverview}
              t={this.props.t} />
          }  



        <div className="charts__container">
  
          {this.state.error && (
            <CSSTransition
              in={true}
              timeout={300}
              classNames="anim__fade"
              unmountOnExit
              appear>
              <div className="error">{this.state.error}</div>
            </CSSTransition>)
          }

          <Bar
            id="charts"
            ref={this.chartsElem}
            options={this.state.chartoptions}
            data={this.state.chartData}
            t={this.props.t}
          />   

        </div>

      </div>
    );
  }
}

export default Chartsjs;
