const ConsoBarOfftake = {
  chartType: "consoPos",
  key: 'offtake',
  label: "Afname",
  data: [],
  borderWidth: 0,
  backgroundColor: 'rgba(235, 120, 110, 0.7)',
  borderColor: 'rgba(235, 120, 110, 0.7)',
  stack: 'stackGrp',
  borderRadius: {
    topLeft: 20,
    topRight: 20,
  }
};

const ConsoBarInjection = {
  chartType: "consoNeg",
  key: 'injection',
  label: "Injectie",
  borderWidth: 0,
  backgroundColor: 'rgba(255, 185, 24, 0.4)',
  borderColor: 'rgba(255, 185, 24, 0.4)',
  data: [],
  stack: 'stackGrp',
  borderRadius: {
    bottomRight: 20,
    bottomLeft: 20,
  }
};

const GasOfftake = {
  chartType: "consoPos",
  key: 'gas',
  label: "Gas",
  borderWidth: 0,
  backgroundColor: 'rgba(235, 120, 110, 0.7)',
  borderColor: 'rgba(235, 120, 110, 0.7)',
  data: [],
  stack: 'stackGrp',
  borderRadius: {
    bottomRight: 20,
    bottomLeft: 20,
  }
};

export {ConsoBarOfftake, ConsoBarInjection, GasOfftake}
