import { arrowForwardOutline } from 'ionicons/icons';
import { useState } from 'react';
import { IonContent, IonButton, IonPage, IonInput, IonItem, IonIcon, useIonViewDidEnter, IonLabel, IonToast, IonLoading } from '@ionic/react';
import './LoginMail.scss';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { CheckKeyOfApp, UpdateKeyOfApp } from '../../hooks/AppKey';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import * as Constants from '../../hooks/Constants';
import { Browser } from '@capacitor/browser';
import { useTranslation } from "react-i18next"

const LoginMail: React.FC = () => {

  const { t, i18n } = useTranslation();

  const [showError, setShowError] = useState(false);
  const [showErrorTxt, setShowErrorTxt] = useState<string>('');

  const [emailValue, setEmailValue] = useState<string>('');

  const { appKey } = CheckKeyOfApp();
  const { updateAppKey } = UpdateKeyOfApp();

  const [showToast, setShowToast] = useState(false);
  const [showToastTxt, setShowToastTxt] = useState<string>('');

  const history = useHistory();

  const [showLoading, setShowLoading] = useState(false);

  useIonViewDidEnter(() => {
    appKey(Constants.KEY_LANG).then(
      value => {
        if (value) {
          i18n.changeLanguage(value);
        }
        else {
          i18n.changeLanguage('nl');
        }
      }
    );
    appKey(Constants.KEY_EMAIL).then(value => setEmailValue(value));    
  });

  function handleKey(key:any) {
    if (key == 'Enter') {
      if(Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }  
      checkEmail();
    }
  }

  function checkEmail() {
    if (validateEmail(emailValue)) {
      setShowLoading(true);
      validateFluviusEmail(emailValue);
    }
    else {
      setShowErrorTxt(t('loginmail.valid_email'));
      setShowError(true);
    }
  }

  function validateFluviusEmail(email:string) {
    const params = new URLSearchParams();
    params.append('client_id', Constants.API_CLIENT_ID);
    params.append('client_secret', Constants.API_CLIENT_SECRET);
    params.append('grant_type', Constants.API_GRANT_TYPE);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    axios.post(Constants.API_URL_TOKEN, params, config)
    .then(
      res => {
        var result:any = res.data;
        var token = result.access_token;
        axios.get(Constants.API_URL_CHECK_USER + email, { headers: {"Authorization" : `Bearer ${token}`} })
        .then(
            res2 => {
              var result2:any = res2.data;
              if (result2.error) {
                setShowErrorTxt(t('loginmail.unknown_email'));
                setShowError(true);
                setShowLoading(false);
              }
              else {
                updateAppKey(Constants.KEY_EMAIL,email);
                history.replace('/setup/password');
              }
            })
        .catch((error2) => {
          setShowToastTxt(t('loginmail.connection_problem'));
          setShowToast(true);
          setShowLoading(false);
        });
      }
    )
    .catch(
      error => {
        setShowToastTxt(t('loginmail.connection_problem'));
        setShowToast(true);
        setShowLoading(false);
      }
    );
  }


  function setText(str:string) {
    setShowError(false);
    setEmailValue(str);
  }

  function validateEmail(email:string) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  function goToBrowser() {
    Browser.open({ url: 'https://flow-energy.be/create-account' });
  }
  
  return (
    <IonPage>
      <IonContent className="email" forceOverscroll={false}>
        <IonLoading
          cssClass="flow-loading"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('loginmail.patience')}
        />
        <img src="assets/logo-flow.svg" className="logo" width="90" height="70" alt="" />
        <h1>{t('loginmail.your_email')}</h1>
        { showError ? (<IonLabel className="message message--error">{ showErrorTxt }</IonLabel>) : '' }
        <IonItem lines="none">
          <IonInput onKeyPress={e=> handleKey(e.key)} type="email" placeholder={t('loginmail.your_email')} value={ emailValue } onIonChange={e => setText(e.detail.value!)}></IonInput>
        </IonItem>
        <p>
        {t('loginmail.explanation')}
        </p>
        <a href="javascript:void(0);" onClick={() => goToBrowser()} target="_blank" className="account-link">{t('loginmail.no_account')}</a>
        <div className="action action--bottom">
          <IonButton shape="round" expand="block" onClick={() => checkEmail()}>{t('loginmail.login')} <IonIcon color="light" icon={arrowForwardOutline}></IonIcon></IonButton>
        </div>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={ showToastTxt }
          duration={1000}
          position="top"
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default LoginMail;
